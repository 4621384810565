export const translation = {


  // validationEmail: "Введите коректный email",
  // validationMax: "Максимальное количество символов :count",
  // validationMin: "Минимальная количество символов  :count",
  // validationRequired: "Заполните это поле",
  // validationSameAs: "Пароли не совпадают!",
  // password: "Пароль",
  // forgotPassword: "Забыли пароль ?",
  // enter: "Войти",
  // or: "или войдите с помощью",
  // notRegisteredYet: 'Еще не регистрировались ?',
  // register: 'Регистрация',
  // addComment: 'Добавить коментарий',
  // takeAddress: 'Выбрать адрес',
  // addNewAddress: "Добавить новый адрес",
  // changePassword: 'Смена пароля',
  // auth: 'Авторизация',
  // registration: 'Регистрация',
  // recoveryPassword: 'Востановление пароля',
  // country: 'Страна',
  // region: 'Регион',
  // district: 'Район',
  // city: 'Город',
  // street: 'Улица',
  // house: "Дом",
  // apartment: "Квартира",
  // index: 'Индекс',
  // name: 'Имя',
  // lastName: 'Фамилия',
  // secondName: 'Отчество',
  // phone: 'Телефон',
  // setMain: ' Сделать основным адресом',
  // save: 'Сохранить',
  // cancel: "Отмена",
  // comment: 'Коментарий',
  // sendComment: 'Отправить коментарий',
  // mainAddress: 'Основной адрес',
  // add: 'Добавить',
  // send: 'Отправить',
  // enterAccount: 'Войти в аккаунт',
  // forgotYourPassword: 'Вспомнили пароль?',
  // repeatPassword: "Повторите пароль",
  // confirmWith: 'Регистрируясь вы соглашаетесь с',
  // politic: 'политикой конфиденциальности',
  // notRegister: 'Еще не регистрировались?',
  // aboutUs: 'О нас',
  // toDefault: 'По умолчанию',
  // sortByAlphabetical: 'По алфавиту(А-Я)',
  // sortByAlphabeticalReverse: 'По алфавиту(Я-А)',
  // sortByPrice: 'По цене',
  // home: 'Главная',
  // news: 'Новости',
  // successChangeData: "Ваши данные успешно изменены",
  // failChangeData: 'Ошибка изменения данных',
  // youAddressRemove: 'Адрес удален',
  // youAddressRefresh: 'Адрес был изменен',
  // newAddressAdded: 'Новый адрес добавлен',
  // successAddFavourites: 'Товар добавлен в избраное',
  // successAuth: 'Успешная авторизация',
  // authFail: 'Неверный email или пароль',
  // alreadyExist: 'Такой пользователь уже зарегестрирован',
  // catalog:'Каталог',
  // aboutCompany:"О компании",
  // history:"История",
  // contacts:"Контакты",
  // article:"Статьи",
  // help:"Помощь",
  // instruction:"Инструкции",
  // callBack:"Обратная связь",
  // politicLabel:"Политика",
  // rules:"Условия",
  // payment:'Оплата',
  // posts:"Статьи",
  // methodsPayment:"Способы оплаты",
  // commission:"Комиссия",
  // currencyLabel:"Валюта",
  // moneyBack:"Возврат платежей",
  // delivery:'Доставка',
  // deliveryMethods:"Способы доставки",
  // tariffs:'Тарифы',
  // insurance:'Страхование',
  // basket:'Корзина',
  // sameProducts:'Похожие товары',
  // oftenProducts:'С этим товаром часто покупают',
  // all:'Все',
  // successChangePassword:'Пароль успешно изменен',
  // failChangePassword:'Пароль небыл изменен',
  // confirmOrder:'Оформление заказа',
  // order:'Оформление заказа',
  // successAddBasket:'Товар добавлен в корзину',
  // editAddress:'Редактировать адрес',
  // edit:'Редактировать',
  // youAddressEdit:'Адрес удачно изменен',
  // registered:'Уже зарегистрированы ?',
  // successRegister:'Реестрация успешная',
  // instructionSendOnEmail:'Инструкция отправлена на вашу почту',
  // wrongUser:'Такого пользователя нету',
  // pickCard:'Выберите карту',
  // addNewCard:'Добавить карту',
  // commentSend:'Отзыв отправлен и проходит модерацию',
  // validationLengthPhone:'Максимальная длинна телефона :count',
  // inn:'ИНН',
  // message:'Сообщение',
  // title:'Тема',
  // create:'Создать',
  // sort:'Сортировать',
  // filter:'Фильтр',
  // addressDelivery:'Адрес доставки',
  // addAddressDelivery:'Добавить новый адрес',
  // typeDelivery:'Способ доставки',
  // pickTypeDelivery:'Выбрать способ доставки',
  // typePayment:'Способ оплаты',
  // pickTypePayment:'Выбрать способ оплаты',
  // saleProducts:'Продукты с акцией',
  // action:'Акция',
  // allCategory:'Все категории',
  // cardNumber:'Номер карты',
  // cardExpiryMonth:'Месяц',
  // cardExpiryYear:'Год',
  // cardCvv:'Cvv',
  // confirm:'Сохранить',
  // month:'Месяц',
  // year:'Год',
  // cardNumberIncorrect:'Данные карточки не коректные',
  // cardAdded:'Карточка успешно добавлена',
  // noCard:'Сохраненных карт нету',
  // deliveryList:'Список доставок',
  // confirmOrderSuccess:'Заказ оформлен',
  // delivered:'Доставлено',
  // inRoad:'В пути',
  // assembled:'Собран',
  // inProcess:'Комплектуется',
  // orderTitle:'Заказ',
  // successCopy:'Номер скопирован',
  // about:'О магазине',
  // instructions:'Инструкции',
  // feedback:'Обратная связь',
  // politics:'Политика',
  // conditions:'Условия',
  // commision:'Комисия',
  // currency:'Валюта',
  // returnPay:'Возврат платежей',
  // tarifs:'Тарифы',
  // strahovka:'Страхование',
  // g:'г',
  // count:'шт',
  // total:'Всего',
  // result:'Итог',
  // repeatOrder:'Повторить заказ',
  // statusOrder:'Статус заказа',
  // trackingOrderMain:'Отследить посылку',
  // trackingOrderTitle:'Отследить заказ можно на сайте',
  // trackingOrder:'Трекинг посылок',
  // downloadPdf:'Скачать PDF',
  // itemInOrder:'Состав заказа',
  // productTitle:'Название',
  // weight:'Вес',
  // price:'Цена',
  // productCount:'Количество',
  // sum:'Сумма',
  // infoAboutOrder:'Информация о заказе',
  // card:'Картой',
  // weightOrder:'Вес посылки',
  // receiver:'Получатель',
  // address:'Адрес',
  // orderNumber:'Номер посылки',
  // awaiting:'Ожидается поставка',
  // collecting:'Готовится к отправке',
  // finished:'Отправлен',
  // process:'В обработке',
  // noOrders:'Заказов таких нету',
  // new:'Новые',
  // ticketCreate:'Сообщение отправлено',
  // itemPresent:'Подарочные товары',
  // messageSend:'Сообщение отправлено',
  // limitProduct:'Количество данного товара ограничено',
  // youMessagesSend:'Ваше сообщение отправлено',
  // fail:'Что-то пошло не так',
  // sales:'Акции',
  // topSales:'Топ продаж',
  // share:'Поделиться',
  // basketProduct:'Товар',
  // basketEmpty:'Корзина пустая',
  // inBasket:'В корзине',
  // productsOnSum:'товаров на сумму',
  // makeOrder:'Оформить заказ',
  // continueBuy:'Продолжить покупки',
  // makeNewTheme:'Створити нову тему',
  // writeMessage:'Напишите сообщение',
  // number:'Номер',
  // date:'Дата',
  // status:'Статус',
  // deliveryAddress:'Адреса доставки',
  // deleteAddress:'удалить',
  // anotherAddresses:'Другие адреса',
  // changePasswordPrivate:'Изменение пароля',
  // enterOldPassword:'Введите старый пароль',
  // enterNewPassword:'Введите новый пароль',
  // confirmNewPassword:'Повторите новый пароль',
  // changePasswordButton:'Изменить пароль',
  // userInfo:'Личная информация',
  // controlNotifications:'Управление уведомлениями',
  // typeNotifications:'О каком статусе заказа оповещать в email',
  // syncProfile:'Связанные учетные записи',

  // exit: 'Выйти',
  // favoriteProducts: 'Избранные товары',
  // orders: 'Заказы',
  // messages: 'Сообщения',
  // products: 'Товары',
  // productsNotFound: 'Товар не найдено',
  // categoriesAndSubCategories: 'Категории, Подкатегории',
  // userData: 'Личные данные',
  // addToFavorite: 'В избранное',
  // addFeedBack: 'Оставить отзыв',
  // share: 'Поделится',
  // inBasket: 'В корзину',
  // attribute: 'Характеристики',
  // feedback: 'Отзывы',
  // buy: 'Отзывы',
  ///////////////////////////////////////////////
  // totalSum: 'Общая сумма',
  // confirmOrder: 'Подтвердить заказ',
  // deliveryPrice: 'Стоимость доставки',
  // totalOrder: 'Стоимость доставки',
  // backToBasket: 'Вернуться в корзину',
  // successful: 'Your payment was successful',

  // addComment: 'Добавить отзыв',

  // successDeletedFavourites: 'Продукт из выбраных удален',
  //////////////////////////////////////////////


};


//      {{ $t('successCreateTransaction') }}