import {VueSlideToggle} from "vue-slide-toggle";
export default {
  props: ['children','isOpen', 'slug'],
  name: "child-category",
  data() {
    return {
    isOpenChild: false
    }
  },
  components:{
    VueSlideToggle
  }
}