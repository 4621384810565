<template>
  <div class="popup">
    <div class="popup-wrap">
      <div class="popup-bg" @click="closePopup()"></div>
      <div class="popup-container">
        <div class="popup-left">
          <div class="popup-content">
            <slot></slot>
          </div>
        </div>
        <div class="popup-right">
          <div class="popup-banner">
            <img :src="bannerList.auth_image" alt="Ichibanstore.jp" v-if="bannerList.auth_image">
<!--            <img src="../../assets/img/register-old-popup-img.jpg" alt="" v-else>-->
          </div>
          <div class="popup-loader" v-show="bannerListLoading">
            <loader/>
          </div>
        </div>
        <div class="popup-close">
          <button class="popup-close__button" @click="closePopup()">
            <span class="leftright"></span>
            <span class="rightleft"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'popup-wrap-banner',
    props: ['title'],
    computed: {
      ...mapGetters({
        bannerList: 'home/bannerList',
        bannerListLoading: 'home/bannerListLoading',
      }),
    },
    methods: {
      closePopup() {
        this.$emit('close-popup');
      }
    }
  }
</script>
<style lang="scss">
  @import "../../assets/scss/vars";

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 50;
    overflow-y: auto;
    &-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(104, 104, 104, 0.6);
      backdrop-filter: blur(10px);
      transition: background $trans, backdrop-filter $trans;
    }
    &-wrap {
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      overflow-y: auto;
    }
    &-container {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      max-width: 1000px;
      background: #fff;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      border: 1px solid #5B99EB;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
    }
    &-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .preloader div:before {
        background: $blue;
      }
    }
    &-close {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 30px;
      height: 30px;
      z-index: 3;
      &__button {
        width: 30px;
        height: 30px;
        background: none;
        border: none;
        padding: 0;
        position: relative;
      }
      .leftright {
        display: block;
        height: 2px;
        width: 30px;
        position: absolute;
        background-color: white;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: all .3s ease-in;
      }
      .rightleft {
        display: block;
        height: 2px;
        width: 30px;
        position: absolute;
        background-color: white;
        border-radius: 2px;
        transform: rotate(-45deg);
        transition: all .3s ease-in;
      }
      button:hover .leftright {
        transform: rotate(-45deg);
        background-color: white;
      }
      button:hover .rightleft {
        transform: rotate(45deg);
        background-color: white;
      }
    }
    &-left {
      width: calc(100% - 365px);
    }
    &-right {
      position: relative;
      box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 0 5px 5px 0;
    }
    &-banner {
      position: relative;
      z-index: 2;
      width: 365px;
      font-size: 0;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media all and (max-width: 1100px) {
    .popup-wrap {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 900px) {
    .popup-bg {
      position: fixed;
    }
    .popup-wrap {
      height: 100vh;
      padding-left: 15px;
      padding-right: 15px;
      display: block;
    }
    .popup-wrap {
      overflow-y: auto;
    }
  }
  @media all and (max-width: 800px) {
    .popup-container {
      flex-direction: column;
      margin-top: 20px;
    }
    .popup-left {
      width: 100%;
    }
    .popup-right {
      display: none;
    }
    .popup-banner {
      max-width: none;
      height: 250px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .popup-close .leftright,
    .popup-close .rightleft {
      background: black;
    }
  }
  @media screen and (max-width: 425px) {
    .popup-container {
      padding: 15px 20px;
    }
    .popup-top__title {
      font-size: 16px;
    }
    .popup-top {
      padding-bottom: 10px;
    }
  }
</style>

