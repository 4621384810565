import {$http} from '@/utils/http';
import {fixBody, globalLoader} from '@/helpers/variables/index';
import Vue from 'vue'
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  CHANGE_POPUP_RESTORE_PASSWORD,
  CHANGE_POPUP_PERSONAL_DATA,
  SET_LOADING_REGISTRATION,
  CHANGE_POPUP_SET_NEW_PASSWORD,
  CHANGE_POPUP_REGISTER,
  CHANGE_POPUP_LOGIN,
  CHANGE_POPUP_FORGOT_PASSWORD,
  SING_IN_WITH_EMAIL_LOADING,
  SING_IN_WITH_FACEBOOK_LOADING,
  SING_IN_WITH_GOOGLE_LOADING,
  SING_IN_WITH_PHONE_LOADING,
  SEND_CODE_LOADING,
  RESTORE_PASSWORD_LOADING,
  SET_NEW_PASSWORD_LOADING,
  CHANGE_POPUP_REGISTER_AGENT,
  CHANGE_POPUP_REGISTER_AGENT_THANKS,
  SET_TICKETS,
  GET_TICKETS_LOADING,
  SET_TICKET_STATUS
} from '../mutation-types';
import {
  GET_TOKEN,
  GET_TOKEN_PHONE,
  REGISTRATION,
  LOGOUT,
  SING_IN_WITH_SOCIAL,
  SING_IN_WITH_GOOGLE,
  SING_IN_WITH_FACEBOOK,
  SEND_CODE_PHONE,
  RESTORE_PASSWORD,
  SET_NEW_PASSWORD,
  REGISTRATION_AGENT,
  CREATE_TICKET,
  GET_TICKETS,
  SEND_MESSAGE,
  OPEN_TICKET,

} from '../action-types';

const state = {
  // popupRegisterAgentThanks: false,
  // popupRegisterAgent: false,
  isAuthenticated: false,
  popupForgotPassword:false,
  // showRestorePassword: false,
  // showPersonalData: false,
  showSetNewPassword: false,
  // loadingRegistration: false,
  // singInEmailLoading: false,
  // singInPhoneLoading: false,
  popupLogin: false,
  popupRegister: false,
  // checkEmailLoading: false,
  singInGoogleLoading: false,
  singInFacebookLoading: false,
  tickets:null,
  ticketsLoading:false,
  ticketStatus: '',
  // sendCodeLoading: false,
  restorePasswordLoading: false,
  // setNewPasswordLoading: false,
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  popupForgotPassword: state => state.popupForgotPassword,
  ticketsLoading: state => state.ticketsLoading,
  ticketStatus: state => state.ticketStatus,
  // checkEmailLoading: state => state.checkEmailLoading,
  // singInEmailLoading: state => state.singInEmailLoading,
  // singInPhoneLoading: state => state.singInPhoneLoading,
  // showRestorePassword: state => state.showRestorePassword,
  // showPersonalData: state => state.showPersonalData,
  showSetNewPassword: state => state.showSetNewPassword,
  // singIn: state => state.singIn,
  // loadingRegistration: state => state.loadingRegistration,
  popupRegister: state => state.popupRegister,
  popupLogin: state => state.popupLogin,
  // popupRegisterAgent: state => state.popupRegisterAgent,
  // popupRegisterAgentThanks: state => state.popupRegisterAgentThanks,
  singInGoogleLoading: state => state.singInGoogleLoading,
  singInFacebookLoading: state => state.singInFacebookLoading,
  tickets: state => state.tickets,
  // sendCodeLoading: state => state.sendCodeLoading,
  restorePasswordLoading: state => state.restorePasswordLoading,
  // setNewPasswordLoading: state => state.setNewPasswordLoading
};
//
const actions = {
  [GET_TOKEN]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_EMAIL_LOADING, true);
    try {
      const result = await $http.post('v1/auth/login', payload);
      commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_EMAIL_LOADING, false);
    }
  },
  [CREATE_TICKET]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_EMAIL_LOADING, true);
    try {
      const result = await $http.post('v1/auth/ticket/create', payload);
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_EMAIL_LOADING, false);
    }
  },
  [SEND_MESSAGE]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_EMAIL_LOADING, true);
    try {
      const result = await $http.post(`v1/auth/ticket/${payload.id}/new_message`,payload.text);
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_EMAIL_LOADING, false);
    }
  },
  [OPEN_TICKET]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_EMAIL_LOADING, true);
    try {
      const result = await $http.post(`v1/auth/ticket/${payload.id}/read`);
      commit(SET_TICKET_STATUS, result.data.message);
      console.log(result.data.message)
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_EMAIL_LOADING, false);
    }
  },
  [GET_TICKETS]: async ({commit}) => {
    commit(GET_TICKETS_LOADING, true);
    try {
      const result = await $http.get('v1/auth/ticket');
      commit(SET_TICKETS, result.data.data);

    } catch (e) {
      throw e;
    } finally {
      commit(GET_TICKETS_LOADING, false);
    }
  },
  // [SEND_CODE_PHONE]: async ({commit}, payload) => {
  //   commit(SEND_CODE_LOADING, true);
  //   try {
  //     const result = await $http.post(`v1/auth/sendCode/${payload.phone}`, payload);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(SEND_CODE_LOADING, false);
  //   }
  // },
  // [GET_TOKEN_PHONE]: async ({commit}, payload) => {
  //   commit(SING_IN_WITH_PHONE_LOADING, true);
  //   try {
  //     const result = await $http.post('v1/auth/loginByCode', payload);
  //     commit(SET_TOKEN, result.data.access_token);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(SING_IN_WITH_PHONE_LOADING, false);
  //   }
  // },
  [SING_IN_WITH_SOCIAL]: async ({commit}, payload) => {
    // commit(SING_IN_WITH_GOOGLE_LOADING, true);
    try {
      const result = await $http.post('v1/auth/social_login', payload);
      commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      // commit(SING_IN_WITH_GOOGLE_LOADING, false);
    }
  },
  // [SING_IN_WITH_FACEBOOK]: async ({commit}, payload) => {
  //   commit(SING_IN_WITH_FACEBOOK_LOADING, true);
  //   try {
  //     const result = await $http.post('v1/auth/login/facebook', payload);
  //     commit(SET_TOKEN, result.data.access_token);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(SING_IN_WITH_FACEBOOK_LOADING, false);
  //   }
  // },
  [LOGOUT]: async ({commit}) => {
    try {
      globalLoader(true);
      return await $http.post('v1/auth/logout');
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_TOKEN);
    }
  }, // eslint-disable-next-line
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);

      const result = await $http.post('v1/auth/registration', payload);
      // commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },// eslint-disable-next-line
  // [REGISTRATION_AGENT]: async ({commit}, payload) => {
  //   try {
  //     // commit(SET_LOADING_REGISTRATION, true);
  //
  //     const result = await $http.post('v1/auth/agent/register-old', payload);
  //     // commit(SET_TOKEN, result.data.access_token);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     // commit(SET_LOADING_REGISTRATION, false);
  //   }
  // },
  [RESTORE_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(RESTORE_PASSWORD_LOADING, true);

      return await $http.post('v1/auth/password/reset', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(RESTORE_PASSWORD_LOADING, false);
    }
  },// eslint-disable-next-line
  [SET_NEW_PASSWORD]: async ({commit}, payload) => {
    try {
      // commit(SET_NEW_PASSWORD_LOADING, true);

      return await $http.post('v1/auth/password/restore', payload);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_NEW_PASSWORD_LOADING, false);
    }
  },
};
//
const mutations = {
  [SET_TOKEN](state, token) {
    localStorage.setItem('user_token', token);
    state.isAuthenticated = true;
  },
  [GET_TICKETS_LOADING](state, status) {
    state.ticketLoading = status
  },
  [SET_TICKET_STATUS](state, status) {
    Vue.set(state, 'ticketStatus', status)
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    window.location.reload();
    state.isAuthenticated = false
  },
  // [CHANGE_POPUP_RESTORE_PASSWORD](state, status) {
  //   state.showRestorePassword = status;
  //   fixBody(status)
  // },
  [CHANGE_POPUP_SET_NEW_PASSWORD](state, status) {
    state.showSetNewPassword = status;
    fixBody(status)
  },
  // [CHANGE_POPUP_PERSONAL_DATA](state, status) {
  //   state.showPersonalData = status;
  //   fixBody(status)
  // },
  //
  // [SET_LOADING_REGISTRATION](state, status) {
  //   state.loadingRegistration = status
  // },
  [CHANGE_POPUP_FORGOT_PASSWORD](state, params) {
    fixBody(params)
    state.popupForgotPassword = params
  },
  [CHANGE_POPUP_REGISTER](state, params) {
    fixBody(params)
    state.popupRegister = params
  },
  [CHANGE_POPUP_LOGIN](state, params) {
    fixBody(params)
    state.popupLogin = params
  },
  [SET_TICKETS](state, data) {
    state.tickets = null
    Vue.set(state, 'tickets', data)
    // state.tickets = null
    // state.tickets = data
  },
  // [CHANGE_POPUP_REGISTER_AGENT](state, params) {
  //   state.popupRegisterAgent = params
  //   fixBody(params)
  // },
  // [CHANGE_POPUP_REGISTER_AGENT_THANKS](state, params) {
  //   state.popupRegisterAgentThanks = params
  //   fixBody(params)
  // },
  // [SING_IN_WITH_EMAIL_LOADING](state, status) {
  //   state.singInEmailLoading = status;
  // },
  // [SING_IN_WITH_PHONE_LOADING](state, status) {
  //   state.singInPhoneLoading = status;
  // },
  // [SING_IN_WITH_GOOGLE_LOADING](state, status) {
  //   state.singInGoogleLoading = status;
  // },
  // [SING_IN_WITH_FACEBOOK_LOADING](state, status) {
  //   state.singInFacebookLoading = status;
  // },
  // [SEND_CODE_LOADING](state, status) {
  //   state.sendCodeLoading = status;
  // },
  [RESTORE_PASSWORD_LOADING](state, status) {
    state.restorePasswordLoading = status;
  },
  // [SET_NEW_PASSWORD_LOADING](state, status) {
  //   state.setNewPasswordLoading = status;
  // },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
