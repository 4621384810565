//about
export const SET_SHORT_CATEGORY_LIST = 'SET_SHORT_CATEGORY_LIST';
export const SET_SHORT_CATEGORY_LIST_LOADING = 'SET_SHORT_CATEGORY_LIST_LOADING';
export const SET_ABOUT_LIST = 'SET_ABOUT_LIST';
export const SET_ABOUT_LIST_LOADING = 'SET_ABOUT_LIST_LOADING';
export const SET_TOPICS = 'SET_TOPICS';
export const SET_ONE_ARTICLE = 'SET_ONE_ARTICLE';
export const SET_SIMILAR_ARTICLES = 'SET_SIMILAR_ARTICLES';
export const SET_QUESTIONS = 'SET_QUESTIONS';


export const SET_ORDERS = 'SET_ORDERS';
export const LOADING_ORDERS = 'LOADING_ORDERS';
//auth
export const CHANGE_POPUP_REGISTER = 'CHANGE_POPUP_REGISTER';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_SET_NEW_PASSWORD = 'CHANGE_POPUP_SET_NEW_PASSWORD';
export const CHANGE_POPUP_PERSONAL_DATA = 'CHANGE_POPUP_PERSONAL_DATA';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const SING_IN_WITH_EMAIL_LOADING = 'SING_IN_WITH_EMAIL_LOADING';
export const SING_IN_WITH_PHONE_LOADING = 'SING_IN_WITH_PHONE_LOADING';
export const SING_IN_WITH_FACEBOOK_LOADING = 'SING_IN_WITH_FACEBOOK_LOADING';
export const SING_IN_WITH_GOOGLE_LOADING = 'SING_IN_WITH_GOOGLE_LOADING';
export const SEND_CODE_LOADING = 'SEND_CODE_LOADING';
export const RESTORE_PASSWORD_LOADING = 'RESTORE_PASSWORD_LOADING';
export const SET_NEW_PASSWORD_LOADING = 'SET_NEW_PASSWORD_LOADING';
export const CHANGE_POPUP_LOGIN = 'CHANGE_POPUP_LOGIN';
export const CHANGE_POPUP_FORGOT_PASSWORD = 'CHANGE_POPUP_FORGOT_PASSWORD';

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
//settings
export const SET_DEFAULT_CURRENCY = 'SET_DEFAULT_CURRENCY';
export const SET_CONFIRM_FLAG = 'SET_CONFIRM_FLAG';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CATALOG_MENU_LOADING = 'SET_CATALOG_MENU_LOADING';
export const SET_CATALOG_MENU = 'SET_CATALOG_MENU';
export const SET_LIST_CATEGORY_FILTERS = 'SET_LIST_CATEGORY_FILTERS';
export const SET_LIST_CATEGORY_FILTERS_LOADING = 'SET_LIST_CATEGORY_FILTERS_LOADING';
export const SET_MAIN_NEWS = 'SET_MAIN_NEWS';
export const SET_CHECKED_FILTERS = 'SET_CHECKED_FILTERS';
export const SET_ONE_NEWS = 'SET_ONE_NEWS';
export const SET_NEWS = 'SET_NEWS';
export const SET_SALES_LIST = 'SET_SALES_LIST';
export const SET_FIRST_CATEGORY = 'SET_FIRST_CATEGORY';

//search
export const SET_HEADER_SEARCH = 'SET_HEADER_SEARCH';
export const SET_PAGE_SEARCH = 'SET_PAGE_SEARCH';
export const SET_PAGE_SEARCH_LOADER = 'SET_PAGE_SEARCH_LOADER';
export const SET_HEADER_SEARCH_LOADER = 'SET_HEADER_SEARCH_LOADER';
export const SHOW_NOT_RESULT_SEARCH_HEADER = 'SHOW_NOT_RESULT_SEARCH_HEADER';
export const SHOW_NOT_RESULT_SEARCH_PAGE = 'SHOW_NOT_RESULT_SEARCH_PAGE';
export const RESET_LIMIT = 'RESET_LIMIT';
export const SET_SEARCH_LIST_LOADER = 'SET_SEARCH_LIST_LOADER';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const SHOW_NOT_RESULT_SEARCH = 'SHOW_NOT_RESULT_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SEARCH_CHANGE_SKIP = 'SEARCH_CHANGE_SKIP';
export const SEARCH_SET_COUNT = 'SEARCH_SET_COUNT';
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH'; 
export const SET_SEARCH_CATEGORY = 'SET_SEARCH_CATEGORY'; 
export const RESET_HEADER_SEARCH_TOOLTIP = 'RESET_HEADER_SEARCH_TOOLTIP';

//contacts
export const SET_LOADING_FEEDBACK = 'SET_LOADING_FEEDBACK';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACTS_LOADING = 'SET_CONTACTS_LOADING';

//system
export const CHANGE_OPEN_HEADER_MENU = 'CHANGE_OPEN_HEADER_MENU';
export const CHANGE_OPEN_FILTER = 'CHANGE_OPEN_FILTER';
export const CHANGE_STATUS_FAVORITE = 'CHANGE_STATUS_FAVORITE';
export const TRANSLATIONS_LOADER = 'TRANSLATIONS_LOADER';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

//home
export const GET_MAIN_SLIDER_LOADING = 'GET_MAIN_SLIDER_LOADING';
export const SET_MAIN_SLIDER = 'SET_MAIN_SLIDER';
export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const SET_LOADING_PRODUCTS_LIST = 'SET_LOADING_PRODUCTS_LIST';
export const SET_BANNER_LIST = 'SET_BANNER_LIST';
export const SET_BANNER_LIST_LOADING = 'SET_BANNER_LIST_LOADING';
export const SET_DISCOUNT_LIST = 'SET_DISCOUNT_LIST';
export const SET_DISCOUNT_LIST_LOADING = 'SET_DISCOUNT_LIST_LOADING';
export const SET_CLIENTS_SLIDER = 'SET_CLIENTS_SLIDER';
export const GET_CLIENTS_SLIDER_LOADING = 'GET_CLIENTS_SLIDER_LOADING';
export const SET_ARTICLES_LIST = 'SET_ARTICLES_LIST';
export const SET_ARTICLES_LIST_LOADING = 'SET_ARTICLES_LIST_LOADING';
export const SET_ARTICLES_ITEM = 'SET_ARTICLES_ITEM';
export const SET_ARTICLES_ITEM_LOADING = 'SET_ARTICLES_ITEM_LOADING';

//products
export const DECREMENT_AVAILABILITY_PROPS = 'DECREMENT_AVAILABILITY_PROPS';
export const INCREMENT_AVAILABILITY_PROPS = 'INCREMENT_AVAILABILITY_PROPS';
export const RESET_AVAILABILITY_PROPS = 'RESET_AVAILABILITY_PROPS';
//buy-flow
export const CHANGE_SHOW_BASKET = 'CHANGE_SHOW_BASKET';
export const CHANGE_SHOW_ORDER = 'CHANGE_SHOW_ORDER';
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const REMOVE_ITEM_FROM_BASKET = 'REMOVE_ITEM_FROM_BASKET';
export const RESET_BASKET = 'RESET_BASKET';
export const DECREMENT_BASKET_EDIT_COUNT = 'DECREMENT_BASKET_EDIT_COUNT';
export const INCREMENT_BASKET_EDIT_COUNT = 'INCREMENT_BASKET_EDIT_COUNT';
export const SET_BASKET_EDIT_COUNT = 'SET_BASKET_EDIT_COUNT';
export const INCREMENT_BASKET_LIST_COUNT = 'INCREMENT_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_LIST_COUNT = 'DECREMENT_BASKET_LIST_COUNT';
export const SET_BASKET_LIST_COUNT = 'SET_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_ITEM = 'DECREMENT_BASKET_ITEM';
export const INCREMENT_BASKET_ITEM = 'INCREMENT_BASKET_ITEM';
export const CHANGE_SHOW_CATALOG_MENU = 'CHANGE_SHOW_CATALOG_MENU';
export const CHANGE_SHOW_CATALOG_MENU_MOBILE = 'CHANGE_SHOW_CATALOG_MENU_MOBILE';
export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING';
export const SET_BASKET_LIST_LOADING = 'SET_BASKET_LIST_LOADING';
export const SET_BASKET_LIST = 'SET_BASKET_LIST';
export const SET_BASKET_LENGTH = 'SET_BASKET_LENGTH';
export const ADD_PRODUCT_TO_BASKET_LOADING = 'ADD_PRODUCT_TO_BASKET_LOADING';
export const REMOVE_PRODUCT_FROM_BASKET_LOADING = 'REMOVE_PRODUCT_FROM_BASKET_LOADING';
export const SEND_BASKET_LOADING = 'SEND_BASKET_LOADING';
export const CHANGE_POPUP_ADDRESSES_LIST = 'CHANGE_POPUP_ADDRESSES_LIST';
export const CHANGE_CARD_INFO = 'CHANGE_CARD_INFO';
export const CHANGE_CARD_LOADING = 'CHANGE_CARD_LOADING';
export const CHANGE_POPUP_DELIVERY = 'CHANGE_POPUP_DELIVERY';
export const CHANGE_DELIVERY_INFO = 'CHANGE_DELIVERY_INFO';
export const CHANGE_STEP_DELIVERY = 'CHANGE_STEP_DELIVERY';
export const CHANGE_STEP_PAY = 'CHANGE_STEP_PAY';
export const RESET_DELIVERY_INFO = 'RESET_DELIVERY_INFO';
export const RESET_CARD_INFO = 'RESET_CARD_INFO';
export const SEND_BASKET_PRODUCTS_IDS_LOADING = 'SEND_BASKET_PRODUCTS_IDS_LOADING';
export const CHANGE_BASKET_PRODUCTS_CURRENCIES = 'CHANGE_BASKET_PRODUCTS_CURRENCIES';

//category
export const SET_FILTER_LIST_LENGTH = 'SET_FILTER_LIST_LENGTH';
export const SET_FILTER_PRODUCTS_LIST = 'SET_FILTER_PRODUCTS_LIST';
export const SET_LOADING_FILTER_PRODUCTS_LIST = 'SET_LOADING_FILTER_PRODUCTS_LIST';
export const SET_LOADING_ALL_PRODUCTS_LIST = 'SET_LOADING_ALL_PRODUCTS_LIST';
export const ACTIVE_CATEGORY = 'ACTIVE_CATEGORY';
export const SET_CATEGORY_LOADING = 'SET_CATEGORY_LOADING';

//setting
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES';
export const SET_SEARCH_ITEMS = 'SET_SEARCH_ITEMS';

//support
export const SET_SUPPORT_LIST_LOADING = 'SET_SUPPORT_LIST_LOADING';
export const SET_SUPPORT_LIST = 'SET_SUPPORT_LIST';


//profile
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING';
export const SET_ACTIVE_ORDER_LIST = 'SET_ACTIVE_ORDER_LIST';
export const SET_ACTIVE_ORDER_LIST_LOADING = 'SET_ACTIVE_ORDER_LIST_LOADING';
export const SET_ARCHIVE_ORDER_LIST = 'SET_ARCHIVE_ORDER_LIST';
export const SET_ARCHIVE_ORDER_LIST_LOADING = 'SET_ARCHIVE_ORDER_LIST_LOADING';
export const SET_FAVOURITE_LIST = 'SET_FAVOURITE_LIST';
export const SET_FAVOURITE_LIST_LOADING = 'SET_FAVOURITE_LIST_LOADING';
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING';
export const ADD_USER_ADDRESS_LOADING = 'ADD_USER_ADDRESS_LOADING';
export const REMOVE_USER_ADDRESS_LOADING = 'REMOVE_USER_ADDRESS_LOADING';
export const EDIT_USER_ADDRESS_LOADING = 'EDIT_USER_ADDRESS_LOADING';
export const ADD_USER_ICON_LOADING = 'ADD_USER_ICON_LOADING';
export const SET_FAVOURITES_LIST_LENGTH = 'SET_FAVOURITES_LIST_LENGTH';
export const FAVOURITES_CHANGE_PAGE = 'FAVOURITES_CHANGE_PAGE';
export const SET_FAVOURITES_LENGTH = 'SET_FAVOURITES_LENGTH';
export const SET_ACTIVE_ORDER_LIST_LENGTH = 'SET_ACTIVE_ORDER_LIST_LENGTH';
export const SET_ARCHIVE_ORDER_LIST_LENGTH = 'SET_ARCHIVE_ORDER_LIST_LENGTH';
export const CHANGE_POPUP_MAIN_ADDRESS = 'CHANGE_POPUP_MAIN_ADDRESS';
export const CHANGE_EDIT_PROFILE_DATA = 'CHANGE_EDIT_PROFILE_DATA';
export const SET_AGENT_CLIENTS = 'SET_AGENT_CLIENTS';
export const CHANGE_POPUP_ADD_ADDRESS = 'CHANGE_POPUP_ADD_ADDRESS';
export const SET_TICKETS = 'SET_TICKETS';
export const SET_TICKET_STATUS = 'SET_TICKET_STATUS';
export const GET_TICKETS_LOADING = 'GET_TICKETS_LOADING';
export const LOADING_COUNTRIES_LIST = 'LOADING_COUNTRIES_LIST';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const LOADING_REGIONS_LIST = 'LOADING_REGIONS_LIST';
export const SET_REGIONS_LIST = 'SET_REGIONS_LIST';

//////TODO delete this
export const SET_DELIVERY_LIST = 'SET_DELIVERY_LIST';
//////TODO delete this

//buy-flow
export const SET_RECOMMENDED_LIST = 'SET_RECOMMENDED_LIST';
export const SET_RECOMMENDED_LIST_LOADING = 'SET_RECOMMENDED_LIST_LOADING';
export const SET_RECOMMENDED_LIST_LENGTH = 'SET_RECOMMENDED_LIST_LENGTH';
export const SET_FAST_BUY_LOADING = 'SET_FAST_BUY_LOADING';
export const CHANGE_POPUP_CARD_PAY = 'CHANGE_POPUP_CARD_PAY';
export const CHANGE_POPUP_CARD_LIST = 'CHANGE_POPUP_CARD_LIST';
export const CHANGE_EDIT_ADDRESS_POPUP = 'CHANGE_EDIT_ADDRESS_POPUP';
export const SET_EDIT_ADDRESS_DATA = 'SET_EDIT_ADDRESS_DATA';
export const CHANGE_ADDRESS_COMPLETE = 'CHANGE_ADDRESS_COMPLETE';

//favorites
export const SET_FAVORITES_LIST_LOADING = 'SET_FAVORITES_LIST_LOADING';
export const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVORITE_LOADING = 'ADD_PRODUCT_TO_FAVORITE_LOADING';
export const UPDATE_FAVORITES_LIST_AFTER_REMOVE_ITEM = 'UPDATE_FAVORITES_LIST_AFTER_REMOVE_ITEM';
export const REMOVE_PRODUCT_IN_FAVORITE_LOADING = 'REMOVE_PRODUCT_IN_FAVORITE_LOADING';
export const SET_FAVORITES_LIST_LENGTH = 'SET_FAVORITES_LIST_LENGTH';
export const ADD_ITEM_TO_FAVOURITES = 'ADD_ITEM_TO_FAVOURITES';
export const REMOVE_ITEM_FROM_FAVOURITES = 'REMOVE_ITEM_FROM_FAVOURITES';
export const SET_SELECT_FAVOURITES_ITEM = 'SET_SELECT_FAVOURITES_ITEM';

// card
export const SET_PROMO_LIST = 'SET_PROMO_LIST';
export const SET_SAME_PRODUCT = 'SET_SAME_PRODUCT';
export const SET_PROMO_LOADING = 'SET_PROMO_LOADING';
export const SET_NEW_COLLECTION_LIST = 'SET_NEW_COLLECTION_LIST';
export const SET_NEW_COLLECTION_LIST_LOADING = 'SET_NEW_COLLECTION_LIST_LOADING';
export const SET_PRODUCT = 'SET_PRODUCT';
export const CHANGE_PRODUCT_LOADING = 'CHANGE_PRODUCT_LOADING';
export const SET_REVIEWS = 'SET_REVIEWS';
export const CHANGE_REVIEWS_LOADING = 'CHANGE_REVIEWS_LOADING';
export const SET_REVIEWS_LENGTH = 'SET_REVIEWS_LENGTH';
export const SET_LOADING_SEND_REVIEW = 'SET_LOADING_SEND_REVIEW';
export const CHANGE_POPUP_FAST_BUY = 'CHANGE_POPUP_FAST_BUY';
export const REVIEWS_CHANGE_PAGE = 'REVIEWS_CHANGE_PAGE';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const RESET_POPULAR_PRODUCT = 'RESET_POPULAR_PRODUCT';
export const SET_POPULAR_PRODUCTS = 'SET_POPULAR_PRODUCTS';
export const CHANGE_POPUP_ADD_COMMENT = 'CHANGE_POPUP_ADD_COMMENT';
export const SET_OFTEN_PRODUCT = 'SET_OFTEN_PRODUCT';

// payments-methods
export const SET_PAYMENT_METHODS_LIST = 'SET_PAYMENT_METHODS_LIST';
export const SET_PAYMENT_METHODS_LIST_LOADING = 'SET_PAYMENT_METHODS_LIST_LOADING';


//payment
export const SET_CARD_LIST = 'SET_CARD_LIST';
export const CHANGE_DELIVERY_LOADER = 'CHANGE_DELIVERY_LOADER';


// delivery-methods
export const SET_DELIVERY_METHODS_LIST = 'SET_DELIVERY_METHODS_LIST';
export const SET_DELIVERY_METHODS_LIST_LOADING = 'SET_DELIVERY_METHODS_LIST_LOADING';

// List
export const CHANGE_SKIP = 'CHANGE_SKIP';
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH';
export const SET_PRODUCTS_ITEM = 'SET_PRODUCTS_ITEM';
export const SET_LOADING_PRODUCTS_ITEM = 'SET_LOADING_PRODUCTS_ITEM';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';
export const SET_PROMO_LIST_LENGTH = 'SET_PROMO_LIST_LENGTH';
export const SET_NEW_LIST_LENGTH = 'SET_NEW_LIST_LENGTH';
export const PRODUCTS_CHANGE_PAGE = 'PRODUCTS_CHANGE_PAGE';
export const CHANGE_SKIP_MOBILE = 'CHANGE_SKIP_MOBILE';
export const SET_MOBILE_PRODUCTS_LIST = 'SET_MOBILE_PRODUCTS_LIST';
export const SET_MOBILE_LIST_LENGTH = 'SET_MOBILE_LIST_LENGTH';
export const SET_MOBILE_LOADING_PRODUCTS_LIST = 'SET_MOBILE_LOADING_PRODUCTS_LIST';

// articles
export const SET_RANDOM_ARTICLES_LIST_LOADING = 'SET_RANDOM_ARTICLES_LIST_LOADING';
export const SET_RANDOM_ARTICLES_LIST = 'SET_RANDOM_ARTICLES_LIST';
export const SET_RANDOM_LIST_LENGTH = 'SET_RANDOM_LIST_LENGTH';

// confirm
export const CHANGE_USER_ADDRESS = 'CHANGE_USER_ADDRESS';
export const RESET_USER_ADDRESS = 'RESET_USER_ADDRESS';
export const NOVA_POSHTA_GET_DEPARTMENS_LOADING = 'NOVA_POSHTA_GET_DEPARTMENS_LOADING';
export const NOVA_POSHTA_GET_TOWNS_LOADING = 'NOVA_POSHTA_GET_TOWNS_LOADING';
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS';
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const SET_INVOICE = 'SET_INVOICE';
export const CHANGE_INVOICE_LOADING = 'CHANGE_INVOICE_LOADING';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const CHANGE_TRANSACTION_LOADING = 'CHANGE_TRANSACTION_LOADING';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_ORDER = 'SET_ORDER';
export const CHANGE_POPUP_CONFIRM_PAYMENT = 'CHANGE_POPUP_CONFIRM_PAYMENT';
//agent-info
export const SET_AGENT_INFO = 'SET_AGENT_INFO';
export const SET_AGENT_LOADING = 'SET_AGENT_LOADING';
export const SET_AGENT_INSTRUCTION = 'SET_AGENT_INSTRUCTION';
export const CHANGE_POPUP_REGISTER_AGENT = 'CHANGE_POPUP_REGISTER_AGENT';
export const CHANGE_POPUP_REGISTER_AGENT_THANKS = 'CHANGE_POPUP_REGISTER_AGENT_THANKS';

export const SET_CATEGORY_SEARCH = 'SET_CATEGORY_SEARCH';

export const SET_REPEAT_ORDER = 'SET_REPEAT_ORDER';
export const FINISH_ORDER_LOADER = 'FINISH_ORDER_LOADER';

export const SET_ONE_SALE = 'SET_ONE_SALE';

export const SET_PAGE = 'SET_PAGE';
export const SET_POSTS = 'SET_POSTS';

