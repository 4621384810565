import { $http } from '@/utils/http'

import {
  SET_DELIVERY_METHODS_LIST,
  SET_DELIVERY_METHODS_LIST_LOADING,
  CHANGE_DELIVERY_INFO,
  RESET_DELIVERY_INFO
} from '../mutation-types';

import {
  GET_DELIVERY_METHODS_LIST,
} from '../action-types';

const state = {
  deliveryMethodsList: null,
  deliveryMethodsListLoading: false,
  deliveryInfo: {},
};


const getters = {
  deliveryMethodsList: state => state.deliveryMethodsList,
  deliveryMethodsListLoading: state => state.deliveryMethodsListLoading,
  deliveryInfo: state => state.deliveryInfo,
};

const actions = {
  [GET_DELIVERY_METHODS_LIST]: async ({commit}) => {
    commit(SET_DELIVERY_METHODS_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/deliveries`);
      commit(SET_DELIVERY_METHODS_LIST, response.data.data);
    } catch (e) {
      throw e
    } finally {
      commit(SET_DELIVERY_METHODS_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [SET_DELIVERY_METHODS_LIST](state,list ) {
    state.deliveryMethodsList = list;
  },
  [SET_DELIVERY_METHODS_LIST_LOADING](state, status) {
    state.deliveryMethodsListLoading = status;
  },
  [CHANGE_DELIVERY_INFO](state, data) {
    state.deliveryInfo = data;
  },
  [RESET_DELIVERY_INFO](state) {
    state.deliveryInfo = {};
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
