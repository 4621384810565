import Vue from "vue";
import { $http } from "@/utils/http";

import {
  ADD_ITEM_TO_BASKET,
  REMOVE_ITEM_FROM_BASKET,
  RESET_BASKET,
  DECREMENT_BASKET_ITEM,
  INCREMENT_BASKET_ITEM,
  SET_BASKET_EDIT_COUNT,
  INCREMENT_BASKET_LIST_COUNT,
  DECREMENT_BASKET_LIST_COUNT,
  SET_BASKET_LIST_COUNT,
  CHANGE_SHOW_BASKET,
  DECREMENT_AVAILABILITY_PROPS,
  INCREMENT_AVAILABILITY_PROPS,
  RESET_AVAILABILITY_PROPS,
  SET_FAST_BUY_LOADING,
  CHANGE_SHOW_ORDER,
  CREATE_ORDER_LOADING,
  SET_BASKET_LIST_LOADING,
  SET_BASKET_LIST,
  SET_BASKET_LENGTH,
  ADD_PRODUCT_TO_BASKET_LOADING,
  REMOVE_PRODUCT_FROM_BASKET_LOADING,
  SEND_BASKET_LOADING,
  SET_ORDER,
  CHANGE_POPUP_ADDRESSES_LIST,
  SEND_BASKET_PRODUCTS_IDS_LOADING,
  CHANGE_BASKET_PRODUCTS_CURRENCIES,
  SET_CONFIRM_FLAG
} from "../mutation-types";

import {
  FAST_BUY,
  CREATE_ORDER,
  SEND_BASKET,
  SEND_BASKET_PRODUCTS_IDS,
} from "../action-types";
import { fixBody } from "@/helpers/variables";

function save() {
  const parsed = JSON.stringify(state.basket);
  localStorage.setItem("products", parsed);
}

function calculateAmountItem(item) {
  item.totalAmount =
    item.select_count *
    (item.sale.new_price ? item.sale.new_price : item.price);
  return item;
}

const state = {
  changedBasket: false,
  showBasket: false,
  showOrder: false,
  addedToBasket: Boolean,
  fastBuyLoading: false,
  addBasketLoading: false,
  basketListLength: 0,
  removeBasketLoading: false,
  setBasketLoading: false,
  orderLoading: false,
  countBasketItems: 0,
  count: 1,
  basket: localStorage.getItem("products")
    ? JSON.parse(localStorage.getItem("products"))
    : [],
  basketLoading: false,
  orderResult: null,
  popupAddressesList: false,
};

const getters = {
  countBasketItems: (state) => {
    let count = 0;
    state.basket.forEach((e) => {
      count = count + e.select_count;
    });
    return count;
  },
  changedBasket: (state) => state.changedBasket,
  addedToBasket: (state) => state.addedToBasket,
  showBasket: (state) => state.showBasket,
  addBasketLoading: (state) => state.addBasketLoading,
  removeBasketLoading: (state) => state.removeBasketLoading,
  showOrder: (state) => state.showOrder,
  fastBuyLoading: (state) => state.fastBuyLoading,
  setBasketLoading: (state) => state.setBasketLoading,
  basketLoading: (state) => state.basketLoading,
  orderLoading: (state) => state.orderLoading,
  basket: (state) => state.basket || [],
  count: (state) => state.count,
  basketListLength: (state) => state.basketListLength,
  orderResult: (state) => state.orderResult,
  popupAddressesList: (state) => state.popupAddressesList,
};

const actions = {
  [FAST_BUY]: async ({ commit }, payload) => {
    commit(SET_FAST_BUY_LOADING, true);
    try {
      return await $http.post("v1/buy-one-click", payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [CREATE_ORDER]: async ({ commit }, payload) => {
    commit(CREATE_ORDER_LOADING, true);
    try {
      const response = await $http.post(`v1/cart/submit`, payload);
      commit(SET_ORDER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CREATE_ORDER_LOADING, false);
    }
  },
  [SEND_BASKET]: async ({ commit }, payload) => {
    commit(SEND_BASKET_LOADING, true);
    try {
      return await $http.post(`v1/cart/set`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(SEND_BASKET_LOADING, false);
    }
  },
  [SEND_BASKET_PRODUCTS_IDS]: async ({ commit }, payload) => {
    // commit(SEND_BASKET_PRODUCTS_IDS_LOADING, true);
    try {
      const response = await $http.post(`v1/auth/basket`, payload);
      commit(CHANGE_BASKET_PRODUCTS_CURRENCIES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SEND_BASKET_PRODUCTS_IDS_LOADING, false);
    }
  },
};

const mutations = {
  [CHANGE_BASKET_PRODUCTS_CURRENCIES](state, list) {
    // state.basket = list;
    state.basket = state.basket.map((product) => {
      let updatedProduct = list.find((item => item.id == product.id))
      product.price = updatedProduct.price
      product.state = updatedProduct.state
      product.count = updatedProduct.count
      product.count_is_limited = updatedProduct.count_is_limited
      if(updatedProduct.count_is_limited === 1 && (product.select_count > updatedProduct.count)) {
        product.select_count = updatedProduct.count
        let changedCount = state.basket.some((item) => {
          return item.select_count === updatedProduct.select_count
        })
        if (!changedCount) {
          state.changedBasket = true
        } else state.changedBasket = false
      }
      product.totalAmount = Number(updatedProduct.price) * product.select_count
      product.invalid_deliveries = updatedProduct.invalid_deliveries
      return product
    })
  },
  [SET_BASKET_LIST](state, list) {
    state.basket = list;

    state.basket.forEach((e) => {
      calculateAmountItem(e);
    });
  },
  [SET_BASKET_LIST_LOADING](state, status) {
    state.basketLoading = status;
  },
  [SEND_BASKET_LOADING](state, status) {
    state.setBasketLoading = status;
  },
  [ADD_PRODUCT_TO_BASKET_LOADING](state, status) {
    state.addBasketLoading = status;
  },
  [REMOVE_PRODUCT_FROM_BASKET_LOADING](state, status) {
    state.removeBasketLoading = status;
  },
  [SET_BASKET_LENGTH](state, data) {
    state.basketListLength = data;
  },
  // add item to basket
  [ADD_ITEM_TO_BASKET](state, obj) {
    if(!state.basket.length) {
      state.basket.push(calculateAmountItem(obj));
      state.addedToBasket = true;
    } else {
      const list = state.basket.filter(e => e.id === obj.id)
      if (list.length) {
        const item = state.basket.find(e => e.id === obj.id);
        if(item){
          const index = state.basket.findIndex(e => e.id === obj.id);
          obj.select_count = state.basket[index].select_count + obj.select_count
          if(obj.count_is_limited === 0) {
            Vue.delete(state.basket, index);
            state.basket.push(calculateAmountItem(obj));
            state.addedToBasket = true;
          } else if(obj.count_is_limited === 1 && obj.select_count <= item.count) {
            Vue.delete(state.basket, index);
            state.basket.push(calculateAmountItem(obj));
            state.addedToBasket = true;
          } else {
            state.addedToBasket = false;
          }
        }else{
          state.basket.push(calculateAmountItem(obj));
           state.addedToBasket = true;
        }
      } else {
        state.basket.push(calculateAmountItem(obj));
         state.addedToBasket = true;
      }
    }
    save();
  },
  //remove item
  [REMOVE_ITEM_FROM_BASKET](state, obj) {
    const index = state.basket.findIndex((e) => e.id === obj.id);

    state.basket.splice(index, 1);
    save();
  },
  [RESET_BASKET](state) {
    localStorage.removeItem("products");
    state.basket = [];
  },
  // increment decrement count in basket
  [INCREMENT_BASKET_LIST_COUNT](state, obj) {
    obj.select_count = obj.select_count + 1;
    Vue.set(state.basket, obj.index, calculateAmountItem(obj));
    save();
  },

  [SET_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex((e) => e.id === obj.id);
    Vue.set(state.basket, index, obj);
    save();
  },

  [DECREMENT_BASKET_LIST_COUNT](state, obj) {
    const index = state.basket.findIndex((e) => e.id === obj.id);
    obj.select_count = obj.select_count - 1;
    Vue.set(state.basket, index, calculateAmountItem(obj));
    save();
  },
  [SET_BASKET_EDIT_COUNT](state, val) {
    state.editCount = val;
  },
  [CHANGE_SHOW_BASKET](state, status) {
    state.showBasket = status;
  },
  [CHANGE_SHOW_ORDER](state, status) {
    console.log("change");
    state.showOrder = status;
  },
  [SET_CONFIRM_FLAG](state) {
    state.changedBasket = false;
  },
  [DECREMENT_AVAILABILITY_PROPS](state) {
    if (state.count > 1) {
      state.count--;
    }
  },
  [INCREMENT_AVAILABILITY_PROPS](state) {
    state.count++;
  },
  [RESET_AVAILABILITY_PROPS](state) {
    state.count = 1;
  },
  [DECREMENT_BASKET_ITEM](state) {
    state.count++;
  },
  [INCREMENT_BASKET_ITEM](state) {
    if (state.count > 1) {
      state.count--;
    }
  },

  [SET_FAST_BUY_LOADING](state, status) {
    state.fastBuyLoading = status;
  },
  [CREATE_ORDER_LOADING](state, status) {
    state.orderLoading = status;
  },
  [SET_ORDER](state, data) {
    state.orderResult = data;
  },
  [CHANGE_POPUP_ADDRESSES_LIST](state, status) {
    fixBody(status);
    state.popupAddressesList = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
