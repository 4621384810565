import {$http} from '@/utils/http';
import {
  SET_PRODUCT,
  CHANGE_PRODUCT_LOADING,
  RESET_PRODUCT,
  RESET_POPULAR_PRODUCT,
  SET_POPULAR_PRODUCTS,
  CHANGE_POPUP_ADD_COMMENT,
  SET_SAME_PRODUCT,
  SET_OFTEN_PRODUCT,
  CHANGE_STATUS_FAVORITE,
  SET_BREADCRUMBS
} from '../mutation-types';

import {
  GET_PRODUCT,
  GET_POPULAR_PRODUCTS,
  ADD_COMMENT,
  GET_SAME_PRODUCT,
  GET_OFTEN_PRODUCT,
} from '../action-types';
import {fixBody, globalLoader} from "@/helpers/variables";


const state = {
  currentCategory: null,
  product: null,
  popularProducts: null,
  productLoading: false,
  commentPopup: false,
  sameProducts: null,
  oftenProducts: null,
  popularLoader:false,
  cardBreadcrumbs: null,
};

const getters = {
  product: state => state.product,
  productLoading: state => state.productLoading,
  popularProducts: state => state.popularProducts,
  commentPopup: state => state.commentPopup,
  currentCategory: state => state.currentCategory,
  sameProducts: state => state.sameProducts,
  oftenProducts: state => state.oftenProducts,
  popularLoader: state => state.popularLoader,
  cardBreadcrumbs: state => state.cardBreadcrumbs,
};

const actions = {
  [GET_PRODUCT]: async ({commit}, payload) => {
      // globalLoader(true);
    commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/${payload.slug ? payload.slug : payload}?category_id=${payload.category_id ? payload.category_id : ''}`);
      commit(SET_PRODUCT, response.data.data);
      commit(SET_BREADCRUMBS, response.data.data.breadcrumbs);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCT_LOADING, false);
      // globalLoader(false);
    }
  },
  [GET_SAME_PRODUCT]: async ({commit}, payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/${payload}/same_products`);
      commit(SET_SAME_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [GET_OFTEN_PRODUCT]: async ({commit}, payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/${payload}/often_buy`);
      commit(SET_OFTEN_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [GET_POPULAR_PRODUCTS]: async ({commit}) => {
    commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/product/popular`);
      commit(SET_POPULAR_PRODUCTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [ADD_COMMENT]: async ({commit},payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    console.log(payload);
    try {
      return await $http.post(`v1/product/comment/add/${payload.slug}`, payload.text);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PRODUCT](state, obj) {
    state.product = {
      ...obj,
      selected: false
    };
  },
  [SET_SAME_PRODUCT](state, obj) {
    state.sameProducts = obj;
  },
  [SET_OFTEN_PRODUCT](state, obj) {
    state.oftenProducts = obj;
  },
  [CHANGE_POPUP_ADD_COMMENT](state, status) {
    fixBody(status)
    state.commentPopup = status;
  },
  [SET_POPULAR_PRODUCTS](state, obj) {
    obj.map(e => {
      e.selected = false
    })
    state.popularProducts = obj;
    // console.log(obj)
  },
  [CHANGE_STATUS_FAVORITE](state, data) {
    if (state.selected) {
      const index = state.list.findIndex(e => e.id === data.product_id)
      state.selected[index].selected = true
    }
  },
  [RESET_PRODUCT](state) {
    state.product = null;
  },
  [RESET_POPULAR_PRODUCT](state) {
    state.popularProducts = null;
  },
  [CHANGE_PRODUCT_LOADING](state, status) {
    state.popularLoader = status;
  },
  [SET_BREADCRUMBS](state, list) {
    state.cardBreadcrumbs = list;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
