import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';

import {
  GET_TRANSLATIONS
} from '@/store/action-types';

const proxy = {
  getTranslation: async () => {
    return await store.dispatch(`setting/${GET_TRANSLATIONS}`);
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'ru-RU',
    languages: [
      {
        name: 'English',
        title: 'Eng',
        key: 'eng',
        code: 'en-US',
        urlPrefix: 'en',
        translationKey: 'en'
      },
      {
        name: 'Russian',
        title: 'Рус',
        key: 'ru',
        code: 'ru-RU',
        urlPrefix: 'ru',
        translationKey: 'ru',
      },

    ],
    translations: proxy,
  },
});
