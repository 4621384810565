import {mapActions, mapGetters, mapMutations} from "vuex";
import categories from './components/categories/index.vue'
import searchItem from './components/search-item/index.vue'
import {directive as onClickaway} from 'vue-clickaway';
import _ from "lodash";

export default {
  name: "main-header",
  data() {
    return {
      showLangList: false,
      burgerToggle: false,
      searchToggle: false,
      showMenuSearch: false,
      categoryActive: false,
      selected: [],
      checked: false,
      showCurrencyList: false,
      show: false,
      showSearchDropdown: false,
      currentCategorySearch: 'all',
      payload: {
        keywords: '',
        category_id: '',
      },
      currency: [
        {
          id: 0,
          title: 'USD $',
          key: 'USD'
        },
        {
          id: 1,
          title: 'JPY ¥',
          key: 'JPY'
        },
        // {
        //   id: 2,
        //   title: 'RUB ₽',
        //   key: 'RUB'
        // },
        {
          id: 2,
          title: 'UAH ₴',
          key: 'UAH'
        },
        {
          id: 3,
          title: 'EUR €',
          key: 'EUR'
        },
      ],
      currentCurrency: [{
        id: null,
        title: '',
        key: ''
      }],

    }
  },

  directives: {
    onClickaway: onClickaway,
  },
  watch: {
    '$route'(newVal) {
      if (newVal) {
        this.burgerToggle = false;
        this.searchToggle = false;
        this.selected = []
      }
    },
    burgerToggle() {
      if (this.burgerToggle) {
        document.body.style.overflowY = 'hidden'
        document.body.style.webkitOverflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'visible'
        document.body.style.webkitOverflowY = 'visible'
      }
    },
    ticketStatus() {
      if (this.ticketStatus === 'Success') {
        this.fetchTickets();
      }
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: `setting/defaultCurrency`,
      isAuthenticated: `auth/isAuthenticated`,
      menuCatalog: `setting/menu`,
      user: `profile/user`,
      searchList: `search/searchList`,
      querySearch: 'search/querySearch',
      resultSearch: 'search/resultHeaderSearch',
      basket: 'basket/basket',
      tickets: `auth/tickets`,
      ticketStatus: `auth/ticketStatus`,
      noResultSearchHeader: 'search/noResultSearchHeader',
      menuLoading: 'setting/menuLoading',
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    totalCount() {
      return this.basket.reduce((accumulator, currentWeight) => {
        return accumulator + currentWeight.select_count;
      }, 0);
    },

    unreadMsg() {
      if (this.isAuthenticated && this.tickets) {
        return this.tickets.reduce((acc, currentTicket) => {
          return acc + currentTicket.unread;
        }, 0)
      } else {
        return 0
      }

    }
  },
  components: {
    categories,
    searchItem
  },
  created() {
    // this.getTitle('USD')
    if (!localStorage.getItem('currency')) {
      // localStorage.setItem('currency', this.getKey('JPY'));
      this.fetchDefaultCurrency().then(() => {
        this.setCurrentCurrency()
        setTimeout(() => {
          location.reload()
        }, 300)
      })

    } else {
      let currency = localStorage.getItem('currency');
      this.getTitle(currency)
    }

    // this.setCurrentCurrency()
    if (this.isAuthenticated) {
      this.fetchTickets()
    }

    // this.setCurrentCurrency()
    // this.currentCurrency = localStorage.getItem('currency') ? this.getTitle(localStorage.getItem('currency')) : JSON.parse([{ id:1,title:'USD $',key:'USD'}])

  },

  methods: {
    ...mapMutations({
      changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
      setQuerySearch: `search/SET_QUERY_SEARCH`,
      setCategorySearch: `search/SET_CATEGORY_SEARCH`,
      setCategoryName: `search/SET_SEARCH_CATEGORY`
    }),

    ...mapActions({
      logout: `auth/LOGOUT`,
      fetchDefaultCurrency: `setting/GET_DEFAULT_CURRENCY`,
      fetchSearch: `search/GET_SEARCH_LIST`,
      fetchSearchTooltip: `search/GET_HEADER_SEARCH_TOOLTIP`,
      fetchTickets: `auth/GET_TICKETS`

    }),
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchSearchTooltip(this.querySearch);
      }
    }, 500),
    changeSearch(query) {
      this.setQuerySearch(query);
      this.sendRequest()

    },
    goToSearchPage() {
      // this.setQuerySearch(this.payload.keywords);
      if (this.payload.keywords.length > 2) {
        this.$router.push({
          name: 'search',
          query: {
            search_text: this.payload.keywords,
            category_id: this.payload.category_id,
            page: 1,
            per_page: 15,
          }
        }).catch(() => {
          console.log()
        });
        this.payload.keywords = ''
      }

    },
    changeCategorySearch(categoryId, title) {
      this.payload.category_id = categoryId;
      this.setCategorySearch(categoryId);
      this.currentCategorySearch = title
      this.setCategoryName(title);
      this.sendRequest()
    },
    setFocusField(status) {
      setTimeout(() => {
        this.show = status;
      }, 300);
    },
    changeProfile() {
      if (this.isAuthenticated) {
        this.$router.push({name: 'profile'}).catch(() => {
          console.log()
        })
      } else {
        this.changePopupLogin(true)
      }
    },
    changeBasket() {
      if (this.isAuthenticated) {
        this.$router.push({name: 'profile-basket'}).catch(() => {
          console.log()
        })
      } else {
        this.changePopupLogin(true)
      }
    },
    closeLangList() {
      this.showLangList = false;
    },
    closeSearch() {
      this.showSearchDropdown = false;
    },
    closeCurrencyList() {
      this.showCurrencyList = false;
    },
    changeLanguage(code) {
      this.$setLanguage(code);
      this.showLangList = false;
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    },
    changeCurrency(currency) {
      localStorage.removeItem('currency')
      localStorage.setItem('currency', currency.key);
      this.showLangList = false;

      setTimeout(() => {
        window.location.reload();
      }, 300)
    },
    getKey(key) {
      // this.currency
      for (let i in this.currency) {
        if (this.currency[i].key === key)
          return this.currency[i].key
      }
    },
    getTitle(key) {
      for (let i in this.currency) {
        if (this.currency[i].key === key) {
          this.currentCurrency = []
          this.currentCurrency.push(this.currency[i])
        }
      }
    },
    setCurrentCurrency() {
      let defaultCurrency = localStorage.getItem('currency')
      if (defaultCurrency) {
        this.getTitle(defaultCurrency)
      } else {
        this.currentCurrency = [this.defaultCurrency]
        localStorage.setItem('currency', this.currentCurrency[0].key)
        // this.currentCurrency = [{id: this.defaultCurrency.id, title: this.defaultCurrency.title, key: this.defaultCurrency.key}]
      }
    },
  },
}
