<template>
  <div class="main-popup">
    <div class="main-popup__wrap">
      <div class="main-popup__bg"></div>
      <div class="main-popup__container" :style="{'max-width': `${maxWidth}px`}">
        <div class="main-popup__top">
          <div class="main-popup__top-title">
            {{ title }}
          </div>
          <div class="main-popup__close">
            <button class="popup-close__button" @click="closePopup()">
              <span class="leftright"></span>
              <span class="rightleft"></span>
            </button>
          </div>
        </div>
        <div class="main-popup__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'popup-wrap-main',
    props: {
      title: {
        type: String,
        default: ''
      },
      maxWidth: {
        type: Number,
        default: 500
      }
    },
    methods: {
      closePopup() {
        this.$emit('close-popup');
      }
    }
  }
</script>
<style lang="scss">
  @import "../../assets/scss/vars";

  .main-popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 50;
    overflow-y: auto;

    &__bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(97, 97, 97, 0.6);
      backdrop-filter: blur(10px);
      z-index: 2;
    }

    &__wrap {
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      overflow-y: auto;
    }

    &__container {
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      max-width: 640px;
      background: $white;
      position: relative;
      z-index: 3;
      //border: 1px solid #5B99EB;
      box-sizing: border-box;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
    }

    &__top {
      width: 100%;
      //border-bottom: solid 2px $blue-light;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 15px;

      &-title {
        text-align: center;
        width: 100%;
        max-width: 90%;
        font-weight: normal;
        font-size: 34px;
        line-height: 40px;
        color: #333333;
      }
    }

    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;
      width: 30px;
      height: 30px;

      &__button {
        width: 30px;
        height: 30px;
        background: none;
        border: none;
        padding: 0;
        position: relative;
      }

      .leftright {
        display: block;
        height: 2px;
        width: 30px;
        position: absolute;
        background-color: black;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: all .3s ease-in;
      }

      .rightleft {
        display: block;
        height: 2px;
        width: 30px;
        position: absolute;
        background-color: black;
        border-radius: 2px;
        transform: rotate(-45deg);
        transition: all .3s ease-in;
      }

      button:hover .leftright {
        transform: rotate(-45deg);
        background-color: black;
      }

      button:hover .rightleft {
        transform: rotate(45deg);
        background-color: black;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .main-popup {
      &__bg {
        position: fixed;
      }

      &__wrap {
        height: 100vh;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
      }

      &__wrap {
        overflow-y: auto;
      }
      &__container {
        height: 80vh;
        overflow-y: scroll;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .main-popup__top {
      padding: 25px 30px;
    }
    .main-popup__top-title {
      font-size: 20px;
      line-height: 24px;
    }
  }
</style>

