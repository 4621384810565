import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

// if (!localStorage.getItem('cardId')) {
//   const cartId = (Math.floor(Math.random()*90000) + 10000).toString();
//   localStorage.setItem('cardId', cartId);
// }

// for multiple parallel requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
    'Accept': 'application/json',
    'Cart-Id':  localStorage.getItem('cardId'),
  }
});
$http.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  config.headers['X-currency'] = localStorage.getItem('currency');
  config.headers['Accept-Locale'] = store.getters.currentLanguage.translationKey;
  return config;
});
$http.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const originalRequest = error.config;
    switch (error.response.status) {
      case 500: {
        router.push({name: 'server-not-found'}).catch(() => {
          console.log()
        });
        break;
      }
      case 404: {
        router.push({name: 'not-found'}).catch(() => {
          console.log()
        });
        break;
      }
      case 401: {
        router.push({name: 'home'}).catch(() => {console.log()});
        store.commit('auth/REMOVE_TOKEN');
        break;
      }
      default: {
        break;
      }
    }
    // if (error.response.status === 401 && !originalRequest.retry) {
    //
    //   if (isRefreshing) {
    //     return new Promise(((resolve, reject) => {
    //       failedQueue.push({resolve, reject});
    //     })).then((token) => {
    //       originalRequest.headers.Authorization = `Bearer ${token}`;
    //       return Vue.axios(originalRequest);
    //     }).catch(error => error);
    //   }
    //
    //   originalRequest.retry = true;
    //   isRefreshing = true;
    //   store.dispatch(`auth/LOGOUT`);
    //   // return new Promise(((resolve, reject) => {
    //   //   $http.post('v1/auth/refresh', {token: localStorage.getItem('user_token')})
    //   //     .then(response => {
    //   //       store.commit(`auth/SET_TOKEN`, response.data.access_token);
    //   //       originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
    //   //       processQueue(null, response.data.access_token);
    //   //       resolve(Vue.axios(originalRequest));
    //   //     })
    //   //     .catch((error) => {
    //   //       console.log(error);
    //   //       router.push({name: 'home'}).catch(() => {console.log()});
    //   //       store.commit(`auth/REMOVE_TOKEN`);
    //   //       processQueue(error, null);
    //   //       reject(error);
    //   //     })
    //   //     .then(() => {
    //   //       isRefreshing = false;
    //   //     });
    //   // }));
    // }
    return Promise.reject(error);
  }
);

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
