import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import Toasted from 'vue-toasted';
// import VueLazyload from 'vue-lazyload'
// import VueCountdownTimer from 'vuejs-countdown-timer'
// import StarRating from 'vue-star-rating';
import Vuelidate from 'vuelidate';
// import VueFlatPickr from 'vue-flatpickr-component';
// import vueSmoothScroll from 'vue2-smooth-scroll'
// import ScrollFixedHeader from 'vuejs-scroll-fixed-header';
// import VueHead from 'vue-head'
// import * as Tabs from 'vue-slim-tabs'

import 'moment/locale/uk'
import 'moment/locale/ru'


//use
// Vue.use(VueCountdownTimer);
Vue.use(Toasted,{duration:5000});
Vue.use(VueTheMask);
// Vue.use(VueLazyload, {
//     preLoad: 1.3,
//     attempt: 1
// })
// Vue.use(StarRating);
Vue.use(Vuelidate);
// Vue.use(VueFlatPickr);
// Vue.use(vueSmoothScroll);
// Vue.use(ScrollFixedHeader);
// Vue.use(VueHead);
// Vue.use(Tabs);
