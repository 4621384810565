import {mapGetters} from "vuex";

export default {
  name: "search-item",
  props: ['item'],
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters({
      currentCurrency :`setting/currentCurrency`
    })
  },
  created() {
  },
  methods: {
  }
}