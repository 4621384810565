import {fixBody, globalLoader} from '@/helpers/variables/index';
import {$http} from '@/utils/http';

import {
  LOADING_USER_DATA,
  SET_ACTIVE_ORDER_LIST,
  SET_ACTIVE_ORDER_LIST_LOADING,
  SET_ARCHIVE_ORDER_LIST,
  SET_ARCHIVE_ORDER_LIST_LOADING,
  SET_USER_DATA,
  UPDATE_USER_DATA_LOADING,
  UPDATE_USER_PASSWORD_LOADING,
  ADD_USER_ADDRESS_LOADING,
  REMOVE_USER_ADDRESS_LOADING,
  EDIT_USER_ADDRESS_LOADING,
  ADD_USER_ICON_LOADING,
  SET_ACTIVE_ORDER_LIST_LENGTH,
  SET_ARCHIVE_ORDER_LIST_LENGTH,
  SET_FAVOURITES_LENGTH,
  CHANGE_POPUP_MAIN_ADDRESS,
  CHANGE_EDIT_PROFILE_DATA,
  SET_AGENT_CLIENTS,
  CHANGE_POPUP_ADD_ADDRESS,
  LOADING_COUNTRIES_LIST,
  SET_COUNTRIES_LIST,
  SET_REGIONS_LIST,
  LOADING_REGIONS_LIST,
  SET_ORDERS,
  LOADING_ORDERS,
} from '../mutation-types';

import {
  ADD_USER_ICON,
  ADD_USER_ADDRESS,
  REMOVE_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  FETCH_USER_DATA,
  GET_ACTIVE_ORDER_LIST,
  GET_ARCHIVE_ORDER_LIST,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  LINK_WITH_GOOGLE,
  LINK_WITH_FACEBOOK,
  UNLINK_WITH_GOOGLE,
  UNLINK_WITH_FACEBOOK,
  GET_AGENT_MONEY,
  ADD_NEW_AGENT_CLIENT,
  GET_AGENT_CLIENTS,
  REMOVE_AGENT_CLIENT,
  EDIT_AGENT_CLIENT,
  EDIT_USER_INFO,
  EDIT_USER_CONTACT,
  CHANGE_MAIN_USER_ADDRESS,
  FETCH_COUNTRIES_LIST,
  FETCH_REGIONS_LIST,
  FETCH_ORDERS,
  FETCH_ORDERS_FILTER
} from '../action-types';

const state = {
  countriesList:null,
  loadingCountriesList:false,
  regionsList:null,
  loadingRegionsList:false,
  user: null,
  loadingUser: false,
  updateUserLoading: false,
  updatePasswordLoading: false,
  activeOrderList: null,
  activeOrderListLength: null,
  activeOrderListLoading: false,
  archiveOrderList: null,
  archiveOrderListLength: null,
  archiveOrderListLoading: false,
  favouriteList: null,
  favouriteListLoading: false,
  addAddressLoading: false,
  removeAddressLoading: false,
  editAddressLoading: false,
  addUserIconLoading: false,
  popupAddAddress:false,
  openPopUpAddressChange: {
    show: false,
    id: null,
  },
  agentClients: [],
  editProfileData: {
    show: false,
    name: '',
    second_name: '',
    last_name: '',
    phone: '',
    email: '',
    birthday: ''
  },
  orders:null,
  ordersLoading:false,
};
//
const getters = {
  editProfileData: state => state.editProfileData,
  agentClients: state => state.agentClients,
  updatePasswordLoading: state => state.updatePasswordLoading,
  openPopUpAddressChange: state => state.openPopUpAddressChange,
  user: state => state.user,
  loadingUser: state => state.loadingUser,
  updateUserLoading: state => state.updateUserLoading,
  activeOrderList: state => state.activeOrderList,
  activeOrderListLength: state => state.activeOrderListLength,
  activeOrderListLoading: state => state.activeOrderListLoading,
  archiveOrderList: state => state.archiveOrderList,
  archiveOrderListLength: state => state.archiveOrderListLength,
  archiveOrderListLoading: state => state.archiveOrderListLoading,
  favouriteList: state => state.favouriteList,
  favouriteListLoading: state => state.favouriteListLoading,
  addAddressLoading: state => state.addAddressLoading,
  removeAddressLoading: state => state.removeAddressLoading,
  editAddressLoading: state => state.editAddressLoading,
  addUserIconLoading: state => state.addUserIconLoading,
  popupAddAddress: state => state.popupAddAddress,
  countriesList: state => state.countriesList,
  loadingCountriesList: state => state.loadingCountriesList,
  regionsList: state => state.regionsList,
  loadingRegionsList: state => state.loadingRegionsList,
  orders: state => state.orders,
  ordersLoading: state => state.ordersLoading,
};

const actions = {
  [FETCH_USER_DATA]: async ({commit}) => {
    try {
      commit(LOADING_USER_DATA, true);
      const response = await $http.get('v1/auth/me');
      // console.log(response.data.data)
      commit(SET_USER_DATA, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_USER_DATA, false);
    }
  },
  [FETCH_ORDERS]: async ({commit},payload) => {
    try {
      commit(LOADING_ORDERS, true);
      const response = await $http.get(`v1/me/orders?status=${payload ? payload : ''}`);
      // console.log(response.data.data)
      commit(SET_ORDERS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ORDERS, false);
    }
  },
  [FETCH_ORDERS_FILTER]: async ({commit},payload) => {
    try {
      commit(LOADING_ORDERS, true);
      console.log(payload)
      const response = await $http.get(`v1/me/orders?status=${payload ? payload : ''}`);
      // console.log(response.data.data)
      commit(SET_ORDERS, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_ORDERS, false);
    }
  },
  [FETCH_COUNTRIES_LIST]: async ({commit}) => {
    try {
      commit(LOADING_COUNTRIES_LIST, true);
      const response = await $http.get('v1/countries');
      commit(SET_COUNTRIES_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_COUNTRIES_LIST, false);
    }
  },
  [FETCH_REGIONS_LIST]: async ({commit},payload) => {
    try {
      commit(LOADING_REGIONS_LIST, true);
      const response = await $http.get(`v1/regions/${payload}`);
      commit(SET_REGIONS_LIST, response.data.data);
      return response;
    } catch (e) {
      throw e;
    } finally {
      commit(LOADING_REGIONS_LIST, false);
    }
  },
  [EDIT_USER_INFO]: async ({commit}, payload) => {
    try {
      const response = await $http.post('v1/auth/edit/info', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [EDIT_USER_CONTACT]: async ({commit}, payload) => {
    try {
      const response = await $http.post('v1/auth/edit/contact', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  // [UPDATE_USER_DATA]: async ({commit}, payload) => {
  //   try {
  //     commit(UPDATE_USER_DATA_LOADING, true);
  //     const response = await $http.put('v1/users', payload);
  //     commit(SET_USER_DATA, response.data.data);
  //     return response;
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(UPDATE_USER_DATA_LOADING, false);
  //   }
  // },
  [UPDATE_USER_PASSWORD]: async ({commit}, payload) => {
    try {
      commit(UPDATE_USER_PASSWORD_LOADING, true);
      return await $http.post('v1/auth/password/change', payload);
    } catch (e) {
      // console.log(e);
      throw e;
    } finally {
      commit(UPDATE_USER_PASSWORD_LOADING, false);
    }
  },
  [LINK_WITH_GOOGLE]: async ({commit}, payload) => {
    try {
      const result = await $http.post('v1/auth/link', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [LINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    try {
      const result = await $http.post('v1/auth/link', payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [UNLINK_WITH_GOOGLE]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/auth/delete/google');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [UNLINK_WITH_FACEBOOK]: async ({commit}, payload) => {
    globalLoader(true);
    try {
      const result = await $http.post('v1/auth/delete/facebook');
    } catch (e) {
      throw e;
    } finally {
      globalLoader(false);
    }
  },
  [GET_ACTIVE_ORDER_LIST]: async ({commit}) => {
    commit(SET_ACTIVE_ORDER_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/user/active-orders`);
      commit(SET_ACTIVE_ORDER_LIST, response.data.data);
      commit(SET_ACTIVE_ORDER_LIST_LENGTH, response.data.data.length);
      // console.log(response.data.data)
    } catch (e) {
      throw e
    } finally {
      commit(SET_ACTIVE_ORDER_LIST_LOADING, false);
    }
  },
  [GET_ARCHIVE_ORDER_LIST]: async ({commit}) => {
    commit(SET_ARCHIVE_ORDER_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/user/archive`);
      commit(SET_ARCHIVE_ORDER_LIST, response.data.data);
      commit(SET_ARCHIVE_ORDER_LIST_LENGTH, response.data.data.length);
    } catch (e) {
      throw e
    } finally {
      commit(SET_ARCHIVE_ORDER_LIST_LOADING, false);
    }
  },
  [ADD_USER_ADDRESS]: async ({commit}, payload) => {
    try {
      commit(ADD_USER_ADDRESS_LOADING, true);
      return await $http.post('v1/address/create', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ADD_USER_ADDRESS_LOADING, false);
    }
  },
  [CHANGE_MAIN_USER_ADDRESS]: async ({commit}, payload) => {
    console.log(payload)
    try {
      // commit(ADD_USER_ADDRESS_LOADING, true);
      return await $http.post(`v1/address/change_main/${payload.id}`, payload.is_main);
    } catch (e) {
      throw e;
    } finally {
      // commit(ADD_USER_ADDRESS_LOADING, false);
    }
  },
  [REMOVE_USER_ADDRESS]: async ({commit}, payload) => {
    console.log(payload);
    try {
      commit(REMOVE_USER_ADDRESS_LOADING, true);
      return await $http.delete(`v1/address/delete/${payload.id}`,payload);
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_USER_ADDRESS_LOADING, false);
    }
  },
  [EDIT_USER_ADDRESS]: async ({commit}, payload) => {
    try {
      commit(EDIT_USER_ADDRESS_LOADING, true);
      return await $http.post(`v1/address/update/${payload.id}`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(EDIT_USER_ADDRESS_LOADING, false);
    }
  },
  [ADD_USER_ICON]: async ({commit}, payload) => {
    try {
      commit(ADD_USER_ICON_LOADING, true);
      return await $http.post(`v1/user/upload-image`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ADD_USER_ICON_LOADING, false);
    }
  },
  [GET_AGENT_MONEY]: async ({commit}, payload) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);

      const result = await $http.post('v1/money_request', payload);
      // commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [ADD_NEW_AGENT_CLIENT]: async ({commit}, payload) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);
      const result = await $http.post('v1/agent_clients', payload);
      // commit(SET_TOKEN, result.data.access_token);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [GET_AGENT_CLIENTS]: async ({commit}) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);
      const result = await $http.get('v1/agent_clients');
      // console.log(result.data.data);
      commit(SET_AGENT_CLIENTS, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [REMOVE_AGENT_CLIENT]: async ({commit}, payload) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);
      const result = await $http.delete(`v1/agent_clients/${payload.id}`);
      // commit(SET_AGENT_CLIENTS, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [EDIT_AGENT_CLIENT]: async ({commit}, payload) => {
    try {
      // commit(SET_LOADING_REGISTRATION, true);
      const result = await $http.put(`v1/agent_clients/${payload.id}`, payload);
      // commit(SET_AGENT_CLIENTS, result.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_REGISTRATION, false);
    }
  },
};
//
const mutations = {
  [CHANGE_EDIT_PROFILE_DATA](state, status) {
    state.editProfileData = status;
  },
  [CHANGE_POPUP_ADD_ADDRESS](state, status) {
    fixBody(status)
    state.popupAddAddress = status;
  },
  [SET_AGENT_CLIENTS](state, data) {
    state.agentClients = data;
  },
  [SET_USER_DATA](state, data) {
    state.user = null
    state.user = data;
  },
  [CHANGE_POPUP_MAIN_ADDRESS](state, status) {
    fixBody(status)
    state.openPopUpAddressChange = status;
  },
  [LOADING_USER_DATA](state, status) {
      state.loadingUser = status;

  },
  [UPDATE_USER_DATA_LOADING](state, status) {
    state.updateUserLoading = status;
  },
  [SET_ACTIVE_ORDER_LIST](state, list) {
    state.activeOrderList = list;
  },
  [SET_ACTIVE_ORDER_LIST_LOADING](state, status) {
    state.activeOrderListLoading = status;
  },
  [SET_ARCHIVE_ORDER_LIST](state, list) {
    state.archiveOrderList = list;
  },
  [SET_ARCHIVE_ORDER_LIST_LOADING](state, status) {
    state.archiveOrderListLoading = status;
  },
  [UPDATE_USER_PASSWORD_LOADING](state, status) {
    state.updatePasswordLoading = status;
  },
  [ADD_USER_ADDRESS_LOADING](state, status) {
    state.addAddressLoading = status;
  },
  [REMOVE_USER_ADDRESS_LOADING](state, status) {
    state.removeAddressLoading = status;
  },
  [EDIT_USER_ADDRESS_LOADING](state, status) {
    state.editAddressLoading = status;
  },
  [ADD_USER_ICON_LOADING](state, status) {
    state.addUserIconLoading = status;
  },
  [SET_ACTIVE_ORDER_LIST_LENGTH](state, data) {
    state.activeOrderListLength = data;
  },
  [SET_ARCHIVE_ORDER_LIST_LENGTH](state, data) {
    state.archiveOrderListLength = data;
  },
  [SET_COUNTRIES_LIST](state, list) {
    state.countriesList = null;
    state.countriesList = list;
  },
  [LOADING_COUNTRIES_LIST](state, status) {
    state.loadingCountriesList = status;
  },
  [SET_REGIONS_LIST](state, list) {
    state.regionsList = null;
    state.regionsList = list;
  },
  [LOADING_REGIONS_LIST](state, status) {
    state.loadingRegionsList = status;
  },
  [SET_ORDERS](state, data) {
    state.orders = null;
    state.orders = data;
  },
  [LOADING_ORDERS](state, status) {
    state.ordersLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
