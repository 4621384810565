import axios from 'axios'

import {
  SET_PAGE,
  SET_POSTS,
  SET_TOPICS,
  SET_ONE_ARTICLE,
  SET_SIMILAR_ARTICLES,
  SET_QUESTIONS

} from '../mutation-types';


import {
  GET_PAGE,
  GET_POSTS,
  SEND_FEEDBACK,
  GET_TOPICS,
  GET_ONE_ARTICLE,
  GET_SIMILAR_ARTICLES,
  GET_QUESTIONS,
  SEND_SUBSCRIBE
} from '../action-types';
import {$http} from "@/utils/http";


const state = {
  page: null,
  posts: null,
  topics: null,
  oneArticle: null,
  similarArticles: null,
  questions: null,
};

const getters = {
  page: state => state.page,
  posts: state => state.posts,
  topics: state => state.topics,
  oneArticle: state => state.oneArticle,
  similarArticles: state => state.similarArticles,
  questions: state => state.questions,
};

const actions = {
  [GET_PAGE]: async ({commit}, payload) => {
    try {
      const response = await $http.get(`v1/page/${payload}`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_QUESTIONS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/questions`);
      commit(SET_QUESTIONS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_POSTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/articles`);
      commit(SET_POSTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [SEND_FEEDBACK]: async ({commit},payload) => {
    try {
      const response = await $http.post(`v1/feedback`,payload);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [SEND_SUBSCRIBE]: async ({commit},payload) => {
    try {
      const response = await $http.post(`v1/subscribe`,payload);

    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_TOPICS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/articles/topics`);
      commit(SET_TOPICS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_ONE_ARTICLE]: async ({commit},payload) => {
    try {
      const response = await $http.get(`v1/articles/${payload}`);
      commit(SET_ONE_ARTICLE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_SIMILAR_ARTICLES]: async ({commit},payload) => {
    try {
      const response = await $http.get(`v1/articles/${payload}/similar`);
      commit(SET_SIMILAR_ARTICLES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
};

const mutations = {
  [SET_PAGE](state, data) {
    state.page = null
    state.page = data
  },
  [SET_POSTS](state, data) {
    state.posts = null
    state.posts = data
  },
  [SET_TOPICS](state, data) {
    state.topics = null
    state.topics = data
  },
  [SET_ONE_ARTICLE](state, data) {
    state.oneArticle = null
    state.oneArticle = data
  },
  [SET_SIMILAR_ARTICLES](state, data) {
    state.similarArticles = null
    state.similarArticles = data
  },
  [SET_QUESTIONS](state, list) {
    state.questions = list
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
