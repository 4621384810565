import {mapMutations, mapGetters} from 'vuex';
const logIn = () => import('@/components/popups/login/index.vue');
const register = () => import('@/components/popups/register/index.vue');
const forgotPassword = () => import('@/components/popups/forgot-password/index.vue');
const newPassword = ()=>import('@/components/popups/new-password/index.vue')
const addAddress = ()=>import('@/components/popups/add-address/index.vue')
const addressesList = ()=>import('@/components/popups/addresses-list/index.vue')
const addComment = ()=>import('@/components/popups/add-comment/index.vue')
const addCard = ()=>import('@/components/popups/add-card/index.vue')
const cardList = ()=>import('@/components/popups/card-list/index.vue')
const editAddress = ()=>import('@/components/popups/edit-address/index.vue')
const deliveryList = ()=>import('@/components/popups/dellivery-list/index.vue')

//
export default {
    name: 'hidden',
    components: {
        logIn,
        register,
        forgotPassword,
        newPassword,
        addAddress,
        addressesList,
        addComment,
        addCard,
        cardList,
        editAddress,
        deliveryList

    },
    computed: {
        ...mapGetters({
            popupRegister: 'auth/popupRegister',
            popupSetNewPassword: 'auth/showSetNewPassword',
            popupLogin: 'auth/popupLogin',
            popupForgotPassword: `auth/popupForgotPassword`,
            popupAddAddress: `profile/popupAddAddress`,
            popupAddressesList: `basket/popupAddressesList`,
            popupAddComment: `card/commentPopup`,
            popupPayCardInfo: `order/popupPayCardInfo`,
            popupPayCardList: `order/popupPayCardList`,
            editAddressPopup: `order/editAddressPopup`,
            popupDeliveryList: `order/popupDeliveryList`,

        })
    },
    methods: {
        ...mapMutations({
            changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
            changePopupForgotPassword: `auth/CHANGE_POPUP_FORGOT_PASSWORD`,
            changeSetNewPassword: `auth/CHANGE_POPUP_SET_NEW_PASSWORD`,
            changePopupLogin: `auth/CHANGE_POPUP_LOGIN`,
            changePopupAddAddress: `profile/CHANGE_POPUP_ADD_ADDRESS`,
            changePopupAddressesList: `basket/CHANGE_POPUP_ADDRESSES_LIST`,
            changePopupAddComment: `card/CHANGE_POPUP_ADD_COMMENT`,
            changePopupCardPay: `order/CHANGE_POPUP_CARD_PAY`,
            changePopupCardList: `order/CHANGE_POPUP_CARD_LIST`,
            changeEditAddress: `order/CHANGE_EDIT_ADDRESS_POPUP`,
            changeDeliveryList: `order/CHANGE_POPUP_DELIVERY`,
        })
    }
}
