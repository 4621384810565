import {$http} from '@/utils/http';
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
  SET_DEFAULT_CURRENCY,
  SET_TRANSLATIONS,
  SET_CATALOG_MENU_LOADING,
  SET_CATALOG_MENU,
  SET_LIST_CATEGORY_FILTERS,
  SET_LIST_CATEGORY_FILTERS_LOADING,
  SET_SUB_CATEGORIES,
  SET_MAIN_NEWS,
  SET_CHECKED_FILTERS,
  SET_BANNER_LIST,
  SET_SEARCH_ITEMS,
  SET_ONE_NEWS,
  SET_NEWS,
  SET_SALES_LIST,
  SET_ONE_SALE, CHANGE_DELIVERY_LOADER,
  TRANSLATIONS_LOADER
} from '../mutation-types';

import {
  GET_DEFAULT_CURRENCY,
  GET_TRANSLATIONS,
  GET_CATEGORIES_MENU,
  GET_CATEGORY_FILTERS,
  GET_SUB_CATEGORIES,
  GET_MAIN_NEWS,
  GET_BANNER_LIST,
  SEARCH,
  GET_ONE_NEWS,
  GET_NEWS,
  GET_SALES_LIST,
  GET_ONE_SALE
} from '../action-types';

const state = {
  defaultCurrency: null,
  menu: null,
  config: null,
  bannerList:null,
  configLoading: false,
  menuLoading: false,
  translations: null,
  filterCategories: null,
  filterCategoriesLoading: false,
  subCategories: null,
  mainNews: null,
  newsList:null,
  checkedFilters:[],
  searchList:null,
  oneNews: null,
  salesList:null,
  saleProducts: null,
  translationsLoader: false,
  currentCurrency: localStorage.getItem('currency') === 'USD' ? '$' : localStorage.getItem('currency') === 'JPY' ? '¥' : localStorage.getItem('currency') === 'RUB' ? '₽' : localStorage.getItem('currency') === 'EUR' ? '€' : localStorage.getItem('currency') === 'UAH' ? '₴':'¥' || this.defaultCurrency.key === 'USD' ? '$' : this.defaultCurrency.key === 'JPY' ? '¥' : this.defaultCurrency.key === 'RUB' ? '₽' : this.defaultCurrency.key === 'EUR' ? '€' : this.defaultCurrency.key === 'UAH' ? '₴':'¥'
};

const getters = {
  defaultCurrency: state => state.defaultCurrency,
  filterCategories: state => state.filterCategories,
  subCategories: state => state.subCategories,
  filterCategoriesLoading: state => state.filterCategoriesLoading,
  menu: state => state.menu,
  translations: state => state.translations,
  config: state => state.config,
  configLoading: state => state.configLoading,
  mainNews: state => state.mainNews,
  checkedFilters: state => state.checkedFilters,
  bannerList: state => state.bannerList,
  searchList: state => state.searchList,
  oneNews: state => state.oneNews,
  currentCurrency: state => state.currentCurrency,
  newsList: state => state.newsList,
  salesList: state => state.salesList,
  menuLoading: state => state.menuLoading,
  saleProducts: state => state.saleProducts,
  translationsLoader: state => state.translationsLoader,
};

const actions = {
  [SEARCH]: async ({commit},payload) => {
    try {
      const response = await $http.get(`v1/autocomplite`, payload);
      commit(SET_SEARCH_ITEMS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_TRANSLATIONS]: async ({commit}) => {
    // globalLoader(true);
    commit(TRANSLATIONS_LOADER, true);
    try {
      const response = await $http.get('v1/translations');
      let result = Object.assign(response.data, translation);
      commit(SET_TRANSLATIONS, result);
      return result;
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      setTimeout(()=>{
        commit(TRANSLATIONS_LOADER, false);
      },1000)
    }
  },
  [GET_DEFAULT_CURRENCY]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/currency`);
      commit(SET_DEFAULT_CURRENCY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_BANNER_LIST]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/banners`);
      commit(SET_BANNER_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_SALES_LIST]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/sale`);
      commit(SET_SALES_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },

  [GET_CATEGORIES_MENU]: async ({commit}) => {
    // globalLoader(true);
    commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get('v1/category');
      commit(SET_CATALOG_MENU, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  // [GET_SUB_CATEGORIES]: async ({commit},slug) => {
  //   globalLoader(true);
  //   // commit(SET_CATALOG_MENU_LOADING, true);
  //   try {
  //     const response = await $http.get(`v1/category/${slug}`);
  //
  //     commit(SET_SUB_CATEGORIES, response.data.data);
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     globalLoader(false);
  //     // commit(SET_CATALOG_MENU_LOADING, false);
  //   }
  // },
  [GET_MAIN_NEWS]: async ({commit},slug) => {
    // globalLoader(true);
    // commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get(`v1/news/is_main`);

      commit(SET_MAIN_NEWS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      // commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  [GET_NEWS]: async ({commit},slug) => {
    // globalLoader(true);
    // commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get(`v1/news`);

      commit(SET_NEWS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      // commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  [GET_ONE_NEWS]: async ({commit},slug) => {
    // globalLoader(true);
    // commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get(`v1/news/${slug}`);
      commit(SET_ONE_NEWS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      // commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
  [GET_ONE_SALE]: async ({commit},slug) => {
    // globalLoader(true);
    // commit(SET_CATALOG_MENU_LOADING, true);
    try {
      const response = await $http.get(`v1/sale/${slug}`);
      commit(SET_ONE_SALE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      // commit(SET_CATALOG_MENU_LOADING, false);
    }
  },
};

const mutations = {
  [SET_CHECKED_FILTERS](state, filter) {
    state.checkedFilters.push(filter);
    console.log(state.checkedFilters);
  },
  [SET_NEWS](state, list) {
    state.newsList = list
  },
  [TRANSLATIONS_LOADER](state, status) {

      state.translationsLoader = status

  },
  [SET_SALES_LIST](state, list) {
    state.salesList = list
  },
  [SET_MAIN_NEWS](state, list) {
    state.mainNews = list;
  },
  [SET_ONE_NEWS](state, news) {
    state.oneNews = news;
  },
  [SET_SEARCH_ITEMS](state, list) {
    state.searchList = list;
  },
  [SET_BANNER_LIST](state, list) {
    state.bannerList = list;
  },
  [SET_DEFAULT_CURRENCY](state, data) {
    state.defaultCurrency = data;
  },
  [SET_LIST_CATEGORY_FILTERS](state, list) {
    state.filterCategories = list;
  },
  [SET_LIST_CATEGORY_FILTERS_LOADING](state, status) {
    state.filterCategoriesLoading = status;
  },
  [SET_TRANSLATIONS](state, status) {
    state.translations = status;
  },
  [SET_CATALOG_MENU_LOADING](state, status) {
      state.menuLoading = status;
  },
  [SET_CATALOG_MENU](state, config) {
    state.menu = config;
  },
  [SET_SUB_CATEGORIES](state, config) {
    state.subCategories = config;
  },
  [SET_ONE_SALE](state, data) {
    state.saleProducts = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
