import axios from 'axios'
// import {fixBody, globalLoader} from '@/helpers/variables/index';

import {
  CHANGE_USER_ADDRESS,
  RESET_USER_ADDRESS,
  CHANGE_USER_INFO,
  RESET_USER_INFO,
  NOVA_POSHTA_GET_DEPARTMENS_LOADING,
  NOVA_POSHTA_GET_TOWNS_LOADING,
  NOVA_POSHTA_SET_DEPARTMENS,
  NOVA_POSHTA_SET_TOWNS,
  CHANGE_STEP_BUY_FLOW,
  RESET_STEP_BUY_FLOW,
  BACK_STEP_BUY_FLOW,
  CHANGE_POPUP_CARD_PAY,
  CHANGE_POPUP_CARD_LIST,
  SET_FAST_BUY_LOADING,
  CHANGE_EDIT_ADDRESS_POPUP,
  SET_EDIT_ADDRESS_DATA,
  CHANGE_ADDRESS_COMPLETE,
  CHANGE_CARD_INFO,
  CHANGE_POPUP_DELIVERY,
  CHANGE_STEP_DELIVERY,
  CHANGE_STEP_PAY,
  RESET_CARD_INFO, SET_DELIVERY_LIST,
  SET_REPEAT_ORDER,
  FINISH_ORDER_LOADER

} from '../mutation-types';


import {
  FAST_BUY,
  NOVA_POSHTA_GET_DEPARTMENS,
  NOVA_POSHTA_GET_TOWNS,
  ADD_CARD, GET_DELIVERY,
  FINISH_ORDER,
  REPEAT_ORDER
} from '../action-types';
import {$http} from "@/utils/http";
import {fixBody} from "@/helpers/variables";

const state = {
  userInfo: {
    id: '',
    country: '',
    region: '',
    district: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    index: '',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    is_main: null,
  },
  cardInfo: {},
  addressComplete: false,
  userAddress: {
    town: '',
    department: '',
    status: false
  },
  editAddressPopup: false,
  isDepartmensLoading: false,
  isTownsLoading: false,
  towns: [],
  departments: [],
  buyFlow: 0,
  popupPayCardInfo: false,
  popupPayCardList: false,
  popupDeliveryList: false,
  stepDelivery:false,
  stepPay:false,
  repeatOrder:null,
  finishOrderLoader:false,
};

const getters = {
  userInfo: state => state.userInfo,
  buyFlow: state => state.buyFlow,
  userAddress: state => state.userAddress,
  isDepartmensLoading: state => state.isDepartmensLoading,
  isTownsLoading: state => state.isTownsLoading,
  towns: state => state.towns,
  departments: state => state.departments,
  popupPayCardInfo: state => state.popupPayCardInfo,
  popupPayCardList: state => state.popupPayCardList,
  editAddressPopup: state => state.editAddressPopup,
  addressComplete: state => state.addressComplete,
  cardInfo: state => state.cardInfo,
  popupDeliveryList: state => state.popupDeliveryList,
  stepDelivery: state => state.stepDelivery,
  stepPay: state => state.stepPay,
  repeatOrder: state => state.repeatOrder,
  finishOrderLoader: state => state.finishOrderLoader,
};

const actions = {
  [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
      commit(NOVA_POSHTA_SET_DEPARTMENS, [])
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
    }
  },
  [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
    commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, true);
    try {
      const response = await axios({
        method: 'POST',
        url: "https://api.novaposhta.ua/v2.0/json/",
        data: payload
      })
      commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
    } catch (e) {
      throw e;
    } finally {
      commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, false);
    }
  },
  [FINISH_ORDER]: async ({commit}, payload) => {
    commit(FINISH_ORDER_LOADER, true);
    try {
      const response = await $http.post(`v1/order/${payload.order_id}/finish`, payload);
      // commit(SET_DELIVERY_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(FINISH_ORDER_LOADER, false);
    }
  },
  [REPEAT_ORDER]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      const response = await $http.get(`v1/order/${payload}/repeat`);
      commit(SET_REPEAT_ORDER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },

};

const mutations = {
  [CHANGE_USER_INFO](state, data) {
    console.log(data)
    state.userInfo = null
    state.userInfo = data
    console.log(state.userInfo)
    // Object.assign(state.userInfo, data);
  },
  // [RESET_USER_INFO](state) {
  //   state.userInfo = null
  // },
  [CHANGE_CARD_INFO](state, data) {
    state.cardInfo = {}
    state.cardInfo = data
  },
  [RESET_CARD_INFO](state) {
    state.cardInfo = {}
  },
  [CHANGE_ADDRESS_COMPLETE](state, status) {
    console.log(status)
    state.addressComplete = status
  },
  [RESET_USER_INFO](state) {
    for (let i in state.userInfo) {
      state.userInfo[i] = ''
    }
  },
  [CHANGE_USER_ADDRESS](state, data) {
    Object.assign(state.userAddress, data);
  },
  [RESET_USER_ADDRESS](state) {
    for (let i in state.userAddress) {
      state.userAddress[i] = ''
    }
  },
  [NOVA_POSHTA_GET_DEPARTMENS_LOADING](state, status) {
    state.isDepartmensLoading = status
  },
  [CHANGE_EDIT_ADDRESS_POPUP](state, status) {
    fixBody(status)
    state.editAddressPopup = status
  },
  [NOVA_POSHTA_GET_TOWNS_LOADING](state, status) {
    state.isTownsLoading = status
  },
  [NOVA_POSHTA_SET_TOWNS](state, data) {
    state.towns = data
  },
  [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
    state.departments = data
  },
  [CHANGE_STEP_BUY_FLOW](state) {
    state.buyFlow += 1
  },
  [BACK_STEP_BUY_FLOW](state) {
    state.buyFlow -= 1
  },
  [RESET_STEP_BUY_FLOW](state) {
    state.buyFlow = 0
  },
  [CHANGE_POPUP_CARD_PAY](state, status) {
    fixBody(status)
    state.popupPayCardInfo = status
  },
  [CHANGE_POPUP_CARD_LIST](state, status) {
    fixBody(status)
    state.popupPayCardList = status
  },
  [SET_EDIT_ADDRESS_DATA](state, data) {
    state.userInfo = data
  },
  [CHANGE_POPUP_DELIVERY](state, status) {
    fixBody(status)
    state.popupDeliveryList = status
  },
  [CHANGE_STEP_DELIVERY](state, status) {
    state.stepDelivery = status
  },
  [CHANGE_STEP_PAY](state, status) {
    state.stepPay = status
  },
  [SET_REPEAT_ORDER](state, data) {
    state.repeatOrder = data
  },
  [FINISH_ORDER_LOADER](state, status) {
    state.finishOrderLoader = status
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
