// import address-list
import Vue from 'vue';
import Loader from '@/components/misc/loader.vue';
import PopupWrapBanner from '@/components/misc/popup-wrap-banner.vue';
import PopupWrapMain from '@/components/misc/popup-wrap-main.vue';
import smallLoader from '@/components/misc/small-loader.vue';
// import VueSmoothScroll from 'vue2-smooth-scroll'
import mainButton from '@/components/main-button/index.vue';



// use global address-list
Vue.component('main-button', mainButton);
// Vue.use(VueSmoothScroll, {
//   duration: 400,
//   updateHistory: false,
// });
Vue.component('loader', Loader);
Vue.component('small-loader', smallLoader);
Vue.component('popup-wrap-banner', PopupWrapBanner);
Vue.component('popup-wrap-main', PopupWrapMain);
Vue.component('v-pagination', window['vue-plain-pagination']);
