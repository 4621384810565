import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store'

Vue.use(Router);
const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '*',
      name: 'not-found',
      component: () => import('./modules/error404/index.vue'),
    },
    {
      path: '/:lang?/server-not-found', // 500
      name: 'server-not-found',
      component: () => import('./modules/error500/index.vue'),
    },
    {
      path: '/:lang?',
      name: 'home',
      component: () => import('./modules/home/index.vue'),
    },
    {
      path: '/:lang?/success-payment',
      name: 'success-payment',
      component: () => import('./modules/success-payment/index.vue'),
    },
    {
      path: '/:lang?/payment-error',
      name: 'payment-error',
      component: () => import('./modules/payment-error/index.vue'),
    },
    {
      path: '/:lang?/news-list',
      name: 'news-list',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'news',
            name: 'news-list',
          },
        ]
      },
      component: () => import('./modules/news-list/index.vue'),

    },
    {
      path: '/:lang?/profile',
      redirect: '/:lang?/profile/user-info',
      name:'profile',
      beforeEnter: isAuthenticated,
      component: () => import('./modules/profile/index.vue'),
      children:[
        {
          path:'user-info',
          name:'profile-user-info',
          component: () => import('./modules/profile/pages/user-info/index.vue'),
        },
        {
          path:'messages',
          name:'profile-messages',
          component: () => import('./modules/profile/pages/messages/index.vue'),
        },
        {
          path:'favorite',
          name:'profile-favorite',
          component: () => import('./modules/profile/pages/favorites/index.vue'),
        },
        {
          path:'basket',
          name:'profile-basket',
          component: () => import('./modules/profile/pages/basket/index.vue'),
        },
        {
          path:'orders',
          name:'profile-orders',
          component: () => import('./modules/profile/pages/orders/index.vue'),
        },
      ]
    },
    {
      path: '/:lang?/buy-flow',
      redirect: '/:lang?/buy-flow/basket',
      name:'buy-flow',
      beforeEnter: isAuthenticated,
      component: () => import('./modules/buy-flow/index.vue'),
      children:[
        {
          path:'basket',
          name:'basket',
          meta: {
            title:'basket',
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'basket',
                name: 'basket',
              },
            ]
          },
          component: () => import('./modules/buy-flow/pages/basket/index.vue'),
        },
        {
          path:'confirm-order',
          name:'confirm-order',
          meta: {
            title:'confirmOrder',
            breadcrumbs: [
              {
                bcDynamic: false,
                bcLabel: 'home',
                name: 'home',
              },
              {
                bcDynamic: false,
                bcLabel: 'basket',
                name: 'basket',
              },
              {
                bcDynamic: false,
                bcLabel: 'order',
                name: 'order',
              },
            ]
          },
          component: () => import('./modules/buy-flow/pages/confirm/index.vue'),
        }

      ]
    },
    {
      path: '/:lang?/categories/:slug',
      name: 'main-catalog',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'catalog',
            name: 'category-all',
          },
          {
            bcDynamic: true,
            bcModule: 'category',
            bcGetter: 'subCategories',
            name: 'catalog',
          },
        ]
      },
      component: () => import('./modules/parent-catalog/index.vue'),
    },
    {
      path: '/:lang?/categories/:slug/catalog/:secondSlug',
      // path: '/:lang?/catalog/:secondSlug',
      name: 'catalog',
      meta: {

        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'catalog',
            name: 'category-all',
          },
          {
            bcDynamic: true,
            bcModule: 'category',
            bcGetter: 'parentCategory',
            name: 'main-catalog',
          },
          {
            bcDynamic: true,
            bcModule: 'category',
            bcGetter: 'categoryBreadcrumbs',
            name: 'catalog',
          },
        ]
      },
      component: () => import('./modules/catalog/index.vue'),
    },
    {
      path: '/:lang?/search',
      name: 'search',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'Search',
            name: 'home'
          },
        ]
      },
      component: () => import('./modules/search/index.vue'),
    },
    {
      // path: '/:lang?/categories/:slug/catalog/:secondSlug',
      path: '/:lang?/category-all',
      name: 'category-all',
      meta: {

        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'catalog',
            name: 'category-all',
          },
        ]
      },
      component: () => import('./modules/category-all/index.vue'),
    },
    {
      // path: '/:lang?/categories/:slug/catalog/:secondSlug',
      path: '/:lang?/sale/:slug',
      name: 'sale',
      meta: {

        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: true,
            bcModule: 'setting',
            bcGetter: 'saleProducts',
            name: 'catalog',
          },
        ]
      },
      component: () => import('./modules/catalog-sale/index.vue'),
    },
    {
      path: '/:lang?/history',
      name: 'history',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'history',
            name: 'history',
          },
        ]
      },
      component: () => import('./modules/history/index.vue'),
      },
    {
      path: '/:lang?/about',
      name: 'about',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'about',
            name: 'about',
          },
        ]
      },
      component: () => import('./modules/about/index.vue'),
      },
    {
      path: '/:lang?/contacts',
      name: 'contacts',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'contacts',
            name: 'contacts',
          },
        ]
      },
      component: () => import('./modules/contacts/index.vue'),
    },
    {
      path: '/:lang?/faq',
      name: 'faq',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'FAQ',
            name: 'faq',
          },
        ]
      },
      component: () => import('./modules/faq/index.vue'),
    },
    {
      path: '/:lang?/posts',
      name: 'posts',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'posts',
            name: 'posts',
          },
        ]
      },
      component: () => import('./modules/posts/index.vue'),
    },
    {
      path: '/:lang?/postPage/:post',
      name: 'postPage',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'posts',
            name: 'posts',
          },
          {
            bcDynamic: true,
            bcModule: 'pages',
            bcGetter: 'oneArticle',
            name: 'postPage',
          },
        ]
      },
      component: () => import('./modules/postPage/index.vue'),
    },
    {
      path: '/:lang?/instructions',
      name: 'instructions',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'instructions',
            name: 'instructions',
          },
        ]
      },
      component: () => import('./modules/instructions/index.vue'),
    },
    {
      path: '/:lang?/feedback',
      name: 'feedback',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'feedback',
            name: 'feedback'
          },
        ]
      },
      component: () => import('./modules/feedback/index.vue'),
    },
    {
      path: '/:lang?/conditions',
      name: 'conditions',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'conditions',
            name: 'conditions'
          },
        ]
      },
      component: () => import('./modules/conditions/index.vue'),
    },
    {
      path: '/:lang?/commision',
      name: 'commision',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'commision',
            name: 'commision'
          },
        ]
      },
      component: () => import('./modules/commision/index.vue'),
    },
    {
      path: '/:lang?/currency',
      name: 'currency',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'currency',
            name: 'currency'
          },
        ]
      },
      component: () => import('./modules/currency/index.vue'),
    },
    {
      path: '/:lang?/payment',
      name: 'payment',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'payment',
            name: 'payment'
          },
        ]
      },
      component: () => import('./modules/payment/index.vue'),
    },
    {
      path: '/:lang?/return-pay',
      name: 'return-pay',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'returnPay',
            name: 'return-pay'
          },
        ]
      },
      component: () => import('./modules/return-pay/index.vue'),
    },
    {
      path: '/:lang?/delivery',
      name: 'delivery',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'delivery',
            name: 'delivery'
          },
        ]
      },
      component: () => import('./modules/delivery/index.vue'),
    },
    {
      path: '/:lang?/tarifs',
      name: 'tarifs',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'tarifs',
            name: 'tarifs'
          },
        ]
      },
      component: () => import('./modules/tarifs/index.vue'),
    },
    {
      path: '/:lang?/duties',
      name: 'duties',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'duties',
            name: 'duties'
          },
        ]
      },
      component: () => import('./modules/duties/index.vue'),
    },
    {
      path: '/:lang?/strahovka',
      name: 'strahovka',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'strahovka',
            name: 'strahovka'
          },
        ]
      },
      component: () => import('./modules/strahovka/index.vue'),
    },
    {
      path: '/:lang?/pop-up',
      name: 'pop-up',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'pop-up',
            name: 'pop-up'
          },
        ]
      },
      component: () => import('./modules/pop-up/index.vue'),
    },
    {
      path: '/:lang?/error500',
      name: 'error500',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'error500',
            name: 'error500'
          },
        ]
      },
      component: () => import('./modules/error500/index.vue'),
    },
    {
      path: '/:lang?/error404',
      name: 'error404',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'error404',
            name: 'error404'
          },
        ]
      },
      component: () => import('./modules/error404/index.vue'),
    },
    {
      path: '/:lang?/politics',
      name: 'politics',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'politics',
            name: 'politics'
          },
        ]
      },
      component: () => import('./modules/politics/index.vue'),
    },
    {
      path: '/:lang?/card/:item',
      name: 'card-redirect',
      component: () => import('./modules/card/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'catalog',
            name: 'category-all',
          },
          {
            bcDynamic: true,
            bcModule: 'card',
            bcGetter: 'product',
            name: 'card',
          },
        ]
      }
    },
    // {
    //   path: '/:lang?/card/:item',
    //   name: 'card-redirect',
    //   component: () => import('./modules/card/index.vue'),
    //   meta: {
    //     breadcrumbs: [
    //       {
    //         bcDynamic: false,
    //         bcLabel: 'catalog',
    //         name: 'category-all',
    //       },
    //       {
    //         bcDynamic: true,
    //         bcModule: 'card',
    //         bcGetter: 'product',
    //         name: 'card',
    //       },
    //     ]
    //   }
    // },
    {
      path: '/:lang?/categories/:slug/catalog/:secondSlug/card/:item',
      // path: '/:lang?/catalog/:secondSlug/card/:item',
      name: 'card',
      component: () => import('./modules/card/index.vue'),
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'catalog',
            name: 'category-all',
          },
          {
            bcDynamic: true,
            bcModule: 'category',
            bcGetter: 'parentCategory',
            name: 'main-catalog',
          },
          {
            bcDynamic: true,
            bcModule: 'category',
            bcGetter: 'subCategories',
            name: 'catalog',
          },
          {
            bcDynamic: true,
            bcModule: 'card',
            bcGetter: 'product',
            name: 'card',
          },
        ]
      },
    },
    {
      path: '/:lang?/is_news/:slug',
      name: 'article',
      meta: {
        breadcrumbs: [
          {
            bcDynamic: false,
            bcLabel: 'home',
            name: 'home',
          },
          {
            bcDynamic: false,
            bcLabel: 'news',
            name: 'news-list'
          },
          {
            bcDynamic: true,
            bcModule: 'setting',
            bcGetter: 'oneNews',
            name: 'article',
          },
        ]
      },
      component: () => import('./modules/article/index.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollLeft = 0;
        el.scrollTop = 0;

        return {x: 0, y: 0}
      }
    }
  }
})
export default router;
