import {VueSlideToggle} from "vue-slide-toggle";
import childCategory from "../child-category/index.vue"
export default {
  props:['subCategories','slug'],
  name: "sub-category",
  components:{
    childCategory,
    VueSlideToggle
  },
  data() {
    return {
      isOpen: false,
    }
  },
}