import {mapGetters} from "vuex";

export default {
  name: "footer",
  computed:{
    ...mapGetters({
      translations:'setting/translations'
    })
  },
  methods: {
    scrollTop() {
      window.scrollTo(0,0);
    }
  }
}