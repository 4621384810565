import Vue from 'vue';
import {$http} from '@/utils/http';
import _ from 'lodash'
import {
  ADD_ITEM_TO_FAVOURITES,
  ADD_PRODUCT_TO_FAVORITE_LOADING,
  REMOVE_ITEM_FROM_FAVOURITES,
  REMOVE_PRODUCT_IN_FAVORITE_LOADING,
  SET_FAVORITES_LIST,
  SET_FAVOURITES_LIST_LENGTH,
  SET_FAVOURITES_LENGTH,
  SET_FAVORITES_LIST_LOADING,
  FAVOURITES_CHANGE_PAGE,
  SET_SELECT_FAVOURITES_ITEM,
} from '../mutation-types';

import {
  ADD_PRODUCT_TO_FAVORITE,
  GET_FAVORITES_LIST,
  REMOVE_PRODUCT_IN_FAVORITE,
} from '../action-types';


const state = {
  list: null,
  editedItem: null,
  loading: false,
  addToFavoriteLoading: false,
  whichList: [],
  whichListLoading: false,
  removeDataItem: null,
  loadingRemoveProductToList: false,
  loadingRemoveList: false,
  favouritesListLength: 0,
  removeItemFromFavouriteLoading: false,
  limit: 6,
  page: 1
};

const getters = {
  loadingRemoveList: state => state.loadingRemoveList,
  removeDataItem: state => state.removeDataItem,
  listId: state => state.listId,
  list: state => state.list,
  loading: state => state.loading,
  addToFavoriteLoading: state => state.addToFavoriteLoading,
  favouriteLength: state => state.favouriteLength,
  whichList: state => state.whichList,
  whichListLoading: state => state.whichListLoading,
  removeItemFromFavouriteLoading: state => state.removeItemFromFavouriteLoading,
  page: state => state.page,
  limit: state => state.limit,
  favouritesListLength: state => state.favouritesListLength,
};

const actions = {
  [GET_FAVORITES_LIST]: async ({commit}) => {
    commit(SET_FAVORITES_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/favorite`);
      commit(SET_FAVORITES_LIST, response.data.data);
      commit(SET_FAVOURITES_LENGTH, response.data.meta.total);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_FAVORITES_LIST_LOADING, false);
    }
  },
  [ADD_PRODUCT_TO_FAVORITE]: async ({commit}, payload) => {
    commit(ADD_PRODUCT_TO_FAVORITE_LOADING, true);
    try {
      const response = await $http.post(`v1/favorite/create`, payload);
      commit(`card/CHANGE_STATUS_FAVORITE`, response.data.data, { root: true });
      commit(ADD_ITEM_TO_FAVOURITES, payload);
      commit(SET_SELECT_FAVOURITES_ITEM, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(ADD_PRODUCT_TO_FAVORITE_LOADING, false);
    }
  },
  [REMOVE_PRODUCT_IN_FAVORITE]: async ({commit}, payload) => {
    try {
      commit(REMOVE_PRODUCT_IN_FAVORITE_LOADING, true);
      return await $http.put(`v1/favorite/delete`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_ITEM_FROM_FAVOURITES, payload);
      commit(REMOVE_PRODUCT_IN_FAVORITE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_FAVORITES_LIST](state, list) {
    state.whichList = list;
  },
  [SET_FAVORITES_LIST_LOADING](state, status) {
    state.whichListLoading = status;
  },
  [ADD_PRODUCT_TO_FAVORITE_LOADING](state, status) {
    state.addToFavoriteLoading = status;
  },
  [REMOVE_PRODUCT_IN_FAVORITE_LOADING](state, status) {
    state.removeItemFromFavouriteLoading = status;
  },


  // add item to favourites
  [ADD_ITEM_TO_FAVOURITES](state, obj) {
    console.log(obj)
    let found = state.whichList.find(e => e.product_id === obj.product_id);
    if (found) {
    } else {
      state.whichList.push(obj);
      state.favouritesListLength++
    }
  },

  //remove item from favourites
  [REMOVE_ITEM_FROM_FAVOURITES](state, obj) {
    console.log(state.whichList);
    let favoriteProducts = []
    for(let i in state.whichList){
      for(let j in state.whichList[i].products){
        favoriteProducts.push(state.whichList[i].products[j])
      }
    }
    const indexWhichList = _.findIndex(favoriteProducts, e => e.id === obj.product_id);
    Vue.delete(state.whichList, indexWhichList)
    state.favouritesListLength--
  },
  [FAVOURITES_CHANGE_PAGE](state, page) {
    state.page = page;
  },
  [SET_FAVOURITES_LENGTH](state, data) {
    state.favouritesListLength = data;
  },
  [SET_SELECT_FAVOURITES_ITEM](state, obj) {
    console.log(state.whichList);
    const index = state.whichList.findIndex(e=> e.product_id === obj.product_id)
    state.whichList[index].selected = 1;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
