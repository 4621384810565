// import Vue from 'vue';
// import _ from 'lodash'

import {$http} from '@/utils/http'

import {
  SET_LIST_LENGTH,
  SET_LOADING_PRODUCTS_LIST,
  SET_PRODUCTS_LIST,
  PRODUCTS_CHANGE_PAGE,
  SET_LOADING_FILTER_PRODUCTS_LIST,
  SET_FILTER_PRODUCTS_LIST,
  SET_FILTER_LIST_LENGTH,
  SET_LOADING_ALL_PRODUCTS_LIST,
  ACTIVE_CATEGORY, SET_LIST_CATEGORY_FILTERS, SET_SUB_CATEGORIES,
  SET_CATEGORY_LOADING,
  SET_LIST_CATEGORY_FILTERS_LOADING,
  SET_FIRST_CATEGORY
} from '../mutation-types';

import {
  GET_PRODUCTS_LIST,
  GET_FILTER_PRODUCTS_LIST,
  GET_ALL_PRODUCTS_LIST,
  GET_CATEGORY_FILTERS, GET_SUB_CATEGORIES
} from '../action-types';
import {globalLoader} from "@/helpers/variables";


const state = {
  filterCategories: null,
  subCategories: null,
  list: null,
  allProductsList: [],
  listLoading: false,
  listLength: false,
  listFilter: null,
  listFilterLoading: false,
  listFilterLength: false,
  loadingAllProductList: false,
  skip: 0,
  count: 0,
  limit: 12,
  page: 1,
  activeCategory: '',
  categoryLoader:false,
  categoryBreadcrumbs:null,
  parentBreadcrumbs:null,
  parentCategory:null,
  breadcrumbsList:null,
};

const getters = {
  list: state => state.list,
  allProductsList: state => state.allProductsList,
  skip: state => state.skip,
  count: state => state.count,
  listLoading: state => state.listLoading,
  listLength: state => state.listLength,
  limit: state => state.limit,
  page: state => state.page,
  listFilter: state => state.listFilter,
  listFilterLoading: state => state.listFilterLoading,
  loadingAllProductList: state => state.loadingAllProductList,
  listFilterLength: state => state.listFilterLength,
  activeCategory: state => state.activeCategory,
  subCategories: state => state.subCategories,
  filterCategories: state => state.filterCategories,
  categoryLoader: state => state.categoryLoader,
  categoryBreadcrumbs: state => state.categoryBreadcrumbs,
  parentCategory: state => state.parentCategory,
  breadcrumbsList: state => state.breadcrumbsList,
};

const actions = {
  [GET_CATEGORY_FILTERS]: async ({commit}, payload ) => {
    commit(SET_LIST_CATEGORY_FILTERS_LOADING, true);
    try {
      const response = await $http.get(`v1/filter/${payload}`);
      commit(SET_LIST_CATEGORY_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      setTimeout(()=>{
        commit(SET_LIST_CATEGORY_FILTERS_LOADING, false);
      },1000)
    }
  },
  [GET_SUB_CATEGORIES]: async ({commit},slug) => {
    // globalLoader(true);
    commit(SET_CATEGORY_LOADING, true);
    try {
      const response = await $http.get(`v1/category/${slug}`);

      commit(SET_SUB_CATEGORIES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // globalLoader(false);
      commit(SET_CATEGORY_LOADING, false);
    }
  },
  [GET_PRODUCTS_LIST]: async ({commit}) => {
    commit(SET_LOADING_PRODUCTS_LIST, true);
    try {
      const response = await $http.get(`v1/products?per-page=${state.limit}&page=${state.page}`);
      commit(SET_PRODUCTS_LIST, response.data.data);
      commit(SET_LIST_LENGTH, response.data.meta.total);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_PRODUCTS_LIST, false);
    }
  },
  [GET_FILTER_PRODUCTS_LIST]: async ({commit}, payload) => {
    // commit(SET_LOADING_FILTER_PRODUCTS_LIST, true);

    let queryParams = payload.sort ? `?sort=${payload.sort}` : '';
    try {
      const response = await $http.post(`v1/filter/${payload.slug}${queryParams}`, payload.data);
      commit(SET_FILTER_PRODUCTS_LIST, response.data.data);
      // commit(SET_FILTER_LIST_LENGTH, response.data.meta.total);
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_LOADING_FILTER_PRODUCTS_LIST, false);
    }
  },
  [GET_ALL_PRODUCTS_LIST]: async ({commit}, payload) => {
    commit(SET_LOADING_ALL_PRODUCTS_LIST, true);
    try {
      const response = await $http.post(`v1/catalog/get-products?per-page=${state.limit}&page=${state.page}`, payload);
      commit(SET_FILTER_PRODUCTS_LIST, response.data.data);
      commit(SET_FILTER_LIST_LENGTH, response.data.meta.total);
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_ALL_PRODUCTS_LIST, false);
    }
  },
};

const mutations = {
  [SET_PRODUCTS_LIST](state, list) {
    // state.subCategories = {}
    // state.subCategories = list;
  },
  [SET_LOADING_PRODUCTS_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, data) {
    state.listLength = data;
  },
  [PRODUCTS_CHANGE_PAGE](state, page) {
    state.page = page;
  },
  [SET_FILTER_PRODUCTS_LIST](state, list) {
    list.products.map(e => {
      e.selected = false
    })
    state.subCategories = null;
    state.subCategories = list;
    // state.breadcrumbsList = list.breadcrumbs
  },
  //////////////////////////////////////////////////
  [SET_LOADING_FILTER_PRODUCTS_LIST](state, status) {
    state.listFilterLoading = status;
  },
  [SET_LOADING_ALL_PRODUCTS_LIST](state, status) {
    state.loadingAllProductList = status;
  },
  [SET_FILTER_LIST_LENGTH](state, data) {
    state.listFilterLength = data;
  },
  [ACTIVE_CATEGORY](state, activeCategory) {
    state.activeCategory = activeCategory;
  },
  [SET_SUB_CATEGORIES](state, data) {
    data.products.map(e => {
      e.selected = false
    })
    state.allProductsList = [];

    let localArr = [];
    console.log('storeData', data)
    if(!data.products.length) {
      if(data.subcategories.length) {
        data.subcategories.forEach((mainSubCat) => {
          if(mainSubCat.products.length) {
             mainSubCat.products.forEach((product) => {
               localArr.push(product);
             })
          }
          if(mainSubCat.subcategories.length) {
            mainSubCat.subcategories.forEach((subCat) => {
              if(subCat.products.length) {
                subCat.products.forEach((product) => {
                  localArr.push(product)
                })
              }
              if(subCat.subcategories.length) {
                localArr = []
                subCat.subcategories.forEach((nextSubCat) => {
                  if(nextSubCat.products.length) {
                    nextSubCat.products.forEach((product) => {
                      localArr.push(product)
                    })
                  }
                })
              }
            })
          }
        })
      } 
    }
    
    if(localArr.length) {
      localArr.forEach((product) => {
        state.allProductsList.push(product);
      })
    }
    
    console.log('storeDoneAll', state.allProductsList)

    state.subCategories = null
    state.subCategories = data;
    state.categoryBreadcrumbs = data
    state.breadcrumbsList = data.breadcrumbs
  },
  //////////////////////////////////////////////////
  [SET_LIST_CATEGORY_FILTERS](state, list) {
    state.filterCategories = list;
  },
  [SET_CATEGORY_LOADING](state, list) {
    state.categoryLoader = list;
  },
  [SET_LIST_CATEGORY_FILTERS_LOADING](state, list) {
    state.categoryLoader = list;
  },
  [SET_FIRST_CATEGORY](state, data) {
    state.parentCategory = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
