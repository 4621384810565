<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <breadcrumbs v-show="$route.name !== 'home' && $route.name !== 'not-found' && $route.name !== 'card-redirect' && $route.name !== 'server-not-found' && $route.name !== 'catalog'&& $route.name !== 'card' && $route.name !== 'catalog'&& $route.name !== 'main-catalog'" :class=" {'disabled': $route.name === 'profile'}"></breadcrumbs>
          <div class="content-wrap">
            <transition name="component-fade" mode="out-in">
              <router-view/>
            </transition>
            <!--            <buy-flow/>-->
          </div>
        </div>
        <div class="wrapper-bottom">
                    <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
    <transition name="component-fade" mode="out-in">
        <div class="global-loader" v-show="translationsLoader">
          <loader></loader>
        </div>
    </transition>
  </div>
</template>
<style src="@/assets/scss/main.scss" lang="scss"></style>
<style src="vue2-advanced-search/dist/AdvancedSearch.css"></style>
<script>

import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import mainFooter from '@/components/footer/index.vue';
import VueMeta from 'vue-meta'
import Vue from 'vue'
import {mapActions,mapMutations,mapGetters} from "vuex";

Vue.use(VueMeta)
export default {
  name: 'App',
  components: {
    MainHeader,
    Hidden,
    Breadcrumbs,
    mainFooter
  },
  metaInfo() {
      return {
        title: `Ichibanstore.jp`,
        separator: ' ',
        // meta: [
        //   {n: 'description', c: this.home.metaDescription, id: 'description'},
        //   {n: 'keywords', c: this.home.metaKeywords, id: 'keywords'},
        //   {n: 'og:title', c: this.home.metaTitle, id: 'og:title'},
        //   {n: 'og:description', c: this.home.metaDescription, id: 'og:description'},
        // ]
      }
  },
  created() {
    if (!localStorage.getItem('favoritesList')) {
      localStorage.setItem('favoritesList', '[]');
    }
    this.fetchBanners()
    if(this.isAuthenticated){
      this.fetchUser()
      this.fetchFavouriteList();
    }
    if(this.$route.query.hasOwnProperty('token') && this.$route.query.hasOwnProperty('email')){
      this.changeSetNewPassword(true);
    }
    this.fetchCategoriesMenu()
  },
  watch:{
    '$route.query'(){
      setTimeout(() => {
        if (this.$route.query.hasOwnProperty('email') && this.$route.query.hasOwnProperty('token')) {
          this.changeSetNewPassword(true)
        }
        // if (this.$route.query.hasOwnProperty('payment')) {
        //   this.$toasted.success(this.$t('successLiqPayPayment'));
        // }
      }, 300)
    }
  },
  computed:{
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      globalLoader: 'system/globalLoader',
      translationsLoader: 'setting/translationsLoader',
    }),

  },
  methods: {
    ...mapActions({
      fetchCategoriesMenu: `setting/GET_CATEGORIES_MENU`,
      fetchUser: `profile/FETCH_USER_DATA`,
      fetchBanners: `setting/GET_BANNER_LIST`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
    }),
    ...mapMutations({
      setCategoriesMenu: `setting/SET_CATALOG_MENU`,
      changeSetNewPassword: `auth/CHANGE_POPUP_SET_NEW_PASSWORD`,
    })
  }
}
</script>

<!--<style>-->
<!--#app {-->
<!--  font-family: Avenir, Helvetica, Arial, sans-serif;-->
<!--  -webkit-font-smoothing: antialiased;-->
<!--  -moz-osx-font-smoothing: grayscale;-->
<!--  text-align: center;-->
<!--  color: #2c3e50;-->
<!--  margin-top: 60px;-->
<!--}-->
<!--</style>-->
