import {$http} from '@/utils/http'
import {
  SET_INVOICE,
  CHANGE_INVOICE_LOADING,
  SET_TRANSACTION,
  CHANGE_TRANSACTION_LOADING,
  RESET_PAYMENT,
  CHANGE_POPUP_CONFIRM_PAYMENT,
  CHANGE_PRODUCT_LOADING,
  RESET_PRODUCT,
  SET_PRODUCT,
  SET_CARD_LIST,
  CHANGE_CARD_LOADING, SET_DELIVERY_LIST,CHANGE_DELIVERY_LOADER
} from '../mutation-types';

import {
  ADD_CARD,
  CREATE_INVOICE,
  CREATE_TRANSACTION, GET_PRODUCT,
  GET_CARD_LIST,
  GET_DELIVERY,
  DELETE_CARD
} from '../action-types';


import {fixBody} from "@/helpers/variables";


const state = {

  invoiceResult: null,
  isInvoiceLoading: false,

  transactionResult: null,
  isTransactionLoading: false,

  productId: null,
  showPopupConfirmPayment: false,
  popupConfirmPaymentLoading: false,

  cardList:null,
  cardLoader:false,

  deliveryList:null,
  deliveryLoader:false,
};

const getters = {

  invoiceResult: state => state.invoiceResult,
  isInvoiceLoading: state => state.isInvoiceLoading,

  transactionResult: state => state.transactionResult,
  isTransactionLoading: state => state.isTransactionLoading,

  productId: state => state.productId,
  showPopupConfirmPayment: state => state.showPopupConfirmPayment,
  popupConfirmPaymentLoading: state => state.popupConfirmPaymentLoading,
  cardList: state => state.cardList,
  cardLoader: state => state.cardLoader,

  deliveryList: state => state.deliveryList,
  deliveryLoader: state => state.deliveryLoader,
};

const actions = {
  [GET_DELIVERY]: async ({commit}, payload) => {
    commit(CHANGE_DELIVERY_LOADER, true);
    try {
      const response = await $http.post('v1/order/delivery_price', payload);
      commit(SET_DELIVERY_LIST, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_DELIVERY_LOADER, false);
    }
  },
  [CREATE_INVOICE]: async ({commit}, payload) => {
    try {
      commit(CHANGE_INVOICE_LOADING, true);

      const response = await $http.post('v1/invoices', payload);
      commit(SET_INVOICE, response.data.data);
      return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_INVOICE_LOADING, false);
    }
  },

  [CREATE_TRANSACTION]: async ({commit}, payload) => {
    try {
      commit(CHANGE_TRANSACTION_LOADING, true);

      const response = await $http.post('v1/transactions', payload);
      if(response.data?.data?.value){
        commit(SET_INVOICE, response.data.data);
      }
      commit(SET_TRANSACTION, response.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_TRANSACTION_LOADING, false);
    }
  },
  [ADD_CARD]: async ({commit}, payload) => {
    // commit(SET_FAST_BUY_LOADING, true);
    try {
      return await $http.post('v1/payment/add_card', payload);
      // return response.data.data;
    } catch (e) {
      throw e;
    } finally {
      // commit(SET_FAST_BUY_LOADING, false);
    }
  },
  [GET_CARD_LIST]: async ({commit}) => {
    // globalLoader(true);
    commit(CHANGE_CARD_LOADING, true);
    try {
      const response = await $http.get(`v1/payment/cards`);
      commit(SET_CARD_LIST, response.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_CARD_LOADING, false);
    }
  },
  [DELETE_CARD]: async ({commit},payload) => {
    // globalLoader(true);
    // commit(CHANGE_CARD_LOADING, true);
    try {
      const response = await $http.post(`v1/payment/destroy_card`,payload);
      // commit(SET_CARD_LIST, response.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_CARD_LOADING, false);
    }
  },
};

const mutations = {
  [SET_INVOICE](state, data) {
    state.invoiceResult = data;
  },
  [SET_CARD_LIST](state, list) {
    state.cardList = list;
  },
  [CHANGE_INVOICE_LOADING](state, status) {
    state.isInvoiceLoading = status;
  },
  [SET_TRANSACTION](state, data) {
    state.transactionResult = data;
  },
  [CHANGE_TRANSACTION_LOADING](state, status) {
    state.isTransactionLoading = status;
  },
  [RESET_PAYMENT](state) {
    state.productId = null;
    state.orderResult = null;
    state.invoiceResult = null;
    state.transactionResult = null;
  },
  [CHANGE_CARD_LOADING](state, status) {
    state.cardLoader = status;

  },
  [CHANGE_POPUP_CONFIRM_PAYMENT](state, status) {
    state.showPopupConfirmPayment = status;
    fixBody(status)
  },
  [SET_DELIVERY_LIST](state, list) {
    console.log(list)
    state.deliveryList = list;
  },
  [CHANGE_DELIVERY_LOADER](state,status) {
    state.deliveryLoader = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
