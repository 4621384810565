//about
export const GET_SHORT_CATEGORY_LIST = 'GET_SHORT_CATEGORY_LIST';
export const GET_ABOUT_LIST = 'GET_ABOUT_LIST';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_FILTER = 'FETCH_ORDERS_FILTER';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const DELETE_CARD = 'DELETE_CARD';
export const SEND_SUBSCRIBE = 'SEND_SUBSCRIBE';

//auth
export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_PHONE = 'GET_TOKEN_PHONE';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
export const SEND_CODE_PHONE = 'SEND_CODE_PHONE';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SING_IN_WITH_SOCIAL = 'SING_IN_WITH_SOCIAL';
export const CREATE_TICKET = 'CREATE_TICKET';
export const GET_TICKETS = 'GET_TICKETS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const OPEN_TICKET = 'OPEN_TICKET';

//setting
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';
export const GET_MAIN_NEWS = 'GET_MAIN_NEWS';
export const SEARCH = 'SEARCH';
export const GET_ONE_NEWS = 'GET_ONE_NEWS';
export const GET_NEWS = 'GET_NEWS';
export const GET_SALES_LIST = 'GET_SALES_LIST';

//support
export const GET_SUPPORT_LIST = 'GET_SUPPORT_LIST';

//search
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
export const GET_HEADER_SEARCH_TOOLTIP = 'GET_HEADER_SEARCH_TOOLTIP';
export const GET_SEARCH_PAGE_RESULT = 'GET_SEARCH_PAGE_RESULT';


//contacts
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_CONTACTS = 'GET_CONTACTS';

//articles
export const GET_TOPICS = 'GET_TOPICS';
export const GET_ONE_ARTICLE = 'GET_ONE_ARTICLE';
export const GET_SIMILAR_ARTICLES = 'GET_SIMILAR_ARTICLES';
//home
export const GET_MAIN_SLIDER = 'GET_MAIN_SLIDER';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const GET_BANNER_LIST = 'GET_BANNER_LIST';
export const GET_DISCOUNT_LIST = 'GET_DISCOUNT_LIST';
export const GET_CLIENTS_SLIDER = 'GET_CLIENTS_SLIDER';
export const GET_ARTICLES_LIST = 'GET_ARTICLES_LIST';
export const GET_ARTICLES_ITEM = 'GET_ARTICLES_ITEM';
export const GET_PRODUCTS_ITEM = 'GET_PRODUCTS_ITEM';
export const GET_MOBILE_PRODUCTS_LIST = 'GET_MOBILE_PRODUCTS_LIST';


//buy-flow
export const GET_RECOMMENDED_LIST = 'GET_RECOMMENDED_LIST';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_BASKET_LIST = 'GET_BASKET_LIST';
export const ADD_PRODUCT_TO_BASKET = 'ADD_PRODUCT_TO_BASKET';
export const REMOVE_PRODUCT_FROM_BASKET = 'REMOVE_PRODUCT_FROM_BASKET';
export const SEND_BASKET = 'SEND_BASKET';
export const SEND_BASKET_PRODUCTS_IDS= 'SEND_BASKET_PRODUCTS_IDS';

//category
export const GET_FILTER_PRODUCTS_LIST = 'GET_FILTER_PRODUCTS_LIST';
export const GET_ATTRIBUTES_PRODUCTS_LIST = 'GET_ATTRIBUTES_PRODUCTS_LIST';
export const GET_ALL_PRODUCTS_LIST = 'GET_ALL_PRODUCTS_LIST';
export const GET_ALL_PRODUCTS_LIST_WITH_ATTRIBUTES = 'GET_ALL_PRODUCTS_LIST_WITH_ATTRIBUTES';


//card
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PROMO_LIST = 'GET_PROMO_LIST';
export const GET_NEW_COLLECTION_LIST = 'GET_NEW_COLLECTION_LIST';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const SEND_REVIEW = 'SEND_REVIEW';
export const FAST_BUY = 'FAST_BUY';
export const ADD_CARD_PRODUCT_TO_BASKET = 'ADD_CARD_PRODUCT_TO_BASKET';
export const GET_POPULAR_PRODUCTS = 'GET_POPULAR_PRODUCTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_SAME_PRODUCT = 'GET_SAME_PRODUCT';
export const GET_OFTEN_PRODUCT = 'GET_OFTEN_PRODUCT';

//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';

//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const GET_DEFAULT_CURRENCY = 'GET_DEFAULT_CURRENCY';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const GET_ACTIVE_ORDER_LIST = 'GET_ACTIVE_ORDER_LIST';
export const GET_ARCHIVE_ORDER_LIST = 'GET_ARCHIVE_ORDER_LIST';
export const GET_FAVOURITE_LIST = 'GET_FAVOURITE_LIST';
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export const REMOVE_USER_ADDRESS = 'REMOVE_USER_ADDRESS';
export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS';
export const ADD_USER_ICON = 'ADD_USER_ICON';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const LINK_WITH_FACEBOOK = 'LINK_WITH_FACEBOOK';
export const UNLINK_WITH_GOOGLE = 'UNLINK_WITH_GOOGLE';
export const UNLINK_WITH_FACEBOOK = 'UNLINK_WITH_FACEBOOK';
export const GET_AGENT_MONEY = 'GET_AGENT_MONEY';
export const ADD_NEW_AGENT_CLIENT = 'ADD_NEW_AGENT_CLIENT';
export const GET_AGENT_CLIENTS = 'GET_AGENT_CLIENTS';
export const REMOVE_AGENT_CLIENT = 'REMOVE_AGENT_CLIENT';
export const EDIT_AGENT_CLIENT = 'EDIT_AGENT_CLIENT';
export const EDIT_USER_INFO = 'EDIT_USER_INFO';
export const EDIT_USER_CONTACT = 'EDIT_USER_CONTACT';
export const CHANGE_MAIN_USER_ADDRESS = 'CHANGE_MAIN_USER_ADDRESS';
export const FETCH_COUNTRIES_LIST = 'FETCH_COUNTRIES_LIST';
export const FETCH_REGIONS_LIST = 'FETCH_REGIONS_LIST';

//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVORITE = 'ADD_PRODUCT_TO_FAVORITE';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';

// payments-methods
export const GET_PAYMENT_METHODS_LIST = 'GET_PAYMENT_METHODS_LIST';

//PAYMENT
export const GET_CARD_LIST = 'GET_CARD_LIST';
export const GET_DELIVERY = 'GET_DELIVERY';

// delivery-methods
export const GET_DELIVERY_METHODS_LIST = 'GET_DELIVERY_METHODS_LIST';

// articles
export const GET_RANDOM_ARTICLES_LIST = 'GET_RANDOM_ARTICLES_LIST';

//agent-info
export const GET_AGENT_INFO = 'GET_AGENT_INFO';
export const GET_AGENT_INSTRUCTION = 'GET_AGENT_INSTRUCTION';
export const REGISTRATION_AGENT = 'REGISTRATION_AGENT';

// confirm
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';
export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

//order
export const ADD_CARD = 'ADD_CARD';
export const FINISH_ORDER = 'FINISH_ORDER';
export const REPEAT_ORDER = 'REPEAT_ORDER';

export const GET_ONE_SALE = 'GET_ONE_SALE';
//PAGE
export const GET_PAGE = 'GET_PAGE';
export const GET_POSTS = 'GET_POSTS';
