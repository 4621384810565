import {$http} from '@/utils/http';
import axios from 'axios';
import {
    SET_HEADER_SEARCH,
    SET_HEADER_SEARCH_LOADER,
    SET_PAGE_SEARCH_LOADER,
    RESET_HEADER_SEARCH_TOOLTIP,
    SHOW_NOT_RESULT_SEARCH_HEADER,
    SHOW_NOT_RESULT_SEARCH_PAGE,
    SET_SEARCH_LIST_LOADER,
    SET_SEARCH_LIST,
    SHOW_NOT_RESULT_SEARCH,
    RESET_SEARCH,
    SEARCH_CHANGE_SKIP,
    SEARCH_SET_COUNT,
    SET_QUERY_SEARCH,
    SET_CATEGORY_SEARCH,
    SET_SEARCH_CATEGORY,
    SET_PAGE_SEARCH, 
    CHANGE_LIMIT, 
    RESET_LIMIT,
} from '../mutation-types';

import {
    GET_HEADER_SEARCH_TOOLTIP,
    GET_SEARCH_LIST,
    GET_SEARCH_PAGE_RESULT,
} from '../action-types';

const state = {
    resultHeaderSearch: [],
    resultPageSearch: [],
    pageSearchLoader: false,
    searchList: null,
    querySearch: '',
    queryCategory: '',
    categoryName: '',
    headerSearchLoader: false,
    searchLoader: false,
    noResultSearch: false,
    noResultSearchHeader: false,
    noResultSearchPage: false,
    skip: 0,
    limit: 15,
    count: 0
};

const getters = {
    searchList: state => state.searchList,
    limit: state => state.limit,
    skip: state => state.skip,
    count: state => state.count,
    querySearch: state => state.querySearch,
    queryCategory: state => state.queryCategory,
    categoryName: state => state.categoryName,
    resultHeaderSearch: state => state.resultHeaderSearch,
    resultPageSearch: state => state.resultPageSearch,
    pageSearchLoader: state => state.pageSearchLoader,
    headerSearchLoader: state => state.headerSearchLoader,
    searchLoader: state => state.searchLoader,
    noResultSearch: state => state.noResultSearch,
    noResultSearchHeader: state => state.noResultSearchHeader,
    noResultSearchPage: state => state.noResultSearchPage,
};

const actions = {
    [GET_HEADER_SEARCH_TOOLTIP]: async ({ commit }, payload) => {
        commit(SET_HEADER_SEARCH_LOADER, true);
        commit(RESET_HEADER_SEARCH_TOOLTIP);
        commit(SHOW_NOT_RESULT_SEARCH_HEADER, false);
        try {
            const response = await $http.get(`v1/autocomplite?search_text=${state.querySearch}&category_id=${state.queryCategory}`);
            response.data.data.total === 0 ? commit(SHOW_NOT_RESULT_SEARCH_HEADER, true) : commit(SHOW_NOT_RESULT_SEARCH_HEADER, false);
            commit(RESET_HEADER_SEARCH_TOOLTIP);
            commit(SET_HEADER_SEARCH, response.data.data);
            return response;
        }catch (e) {
            throw e;
        }finally {
            commit(SET_HEADER_SEARCH_LOADER, false);
        }
    },
    [GET_SEARCH_LIST]: async ({ commit }, payload) => {
        commit(SET_SEARCH_LIST_LOADER, true);
        commit(SHOW_NOT_RESULT_SEARCH, false);
        try {
            const response = await $http.get(`v1/autocomplite?search_text=${state.querySearch}`);
            response.data.data.total === 0 ? commit(SHOW_NOT_RESULT_SEARCH, true) : commit(SHOW_NOT_RESULT_SEARCH, false);
            commit(RESET_SEARCH);
            commit(SET_SEARCH_LIST, response.data.data);
        }catch (e) {
            throw e;
        }finally {
            commit(SET_SEARCH_LIST_LOADER, false);
        }
    },
    [GET_SEARCH_PAGE_RESULT]: async ({ commit }, payload) => {
      commit(SET_PAGE_SEARCH_LOADER, true);
      commit(SHOW_NOT_RESULT_SEARCH_PAGE, false);
      try {
          const response = await $http.get(`v1/search?search_text=${payload.search_text}&category_id=${payload.category_id}&page=${payload.page}&per_page=${payload.per_page}`);
          // response.data.meta.categories.is_not_empty === false &&  response.data.meta.products.is_not_empty ? commit(SHOW_NOT_RESULT_SEARCH_PAGE, true) : commit(SHOW_NOT_RESULT_SEARCH_PAGE, false);
          commit(SET_PAGE_SEARCH, response.data);
          return response;
      }catch (e) {
          throw e;
      }finally {
          commit(SET_PAGE_SEARCH_LOADER, false);
      }
    },
};

const mutations = {
    [SET_HEADER_SEARCH](state, list){
        state.resultHeaderSearch = list;
    },
    [SET_PAGE_SEARCH](state, data){
        state.resultPageSearch = [];
      state.resultPageSearch = data;
    },
    [SET_QUERY_SEARCH](state, query){
        // Object.assign(state.querySearch,query)
        state.querySearch = query;
    },
    [SET_CATEGORY_SEARCH](state, query, title){
        // Object.assign(state.querySearch,query)
        state.queryCategory = query;
    },
    [SET_SEARCH_CATEGORY](state, title){
      state.categoryName = title;
    },
    [SET_SEARCH_LIST](state, list){
        state.searchList = list;
    },
    [RESET_HEADER_SEARCH_TOOLTIP](state){
        state.resultHeaderSearch = [];
    },
    [RESET_SEARCH](state){
        state.searchList = null;
    },
    [SET_HEADER_SEARCH_LOADER](state, status){
        state.headerSearchLoader = status;
    },
    [SET_PAGE_SEARCH_LOADER](state, status){
      state.pageSearchLoader = status;
  },
    [SET_SEARCH_LIST_LOADER](state, status){
        state.searchLoader = status;
    },
    [SHOW_NOT_RESULT_SEARCH_HEADER](state, status){
        state.noResultSearchHeader = status;
    },
    [SHOW_NOT_RESULT_SEARCH_PAGE](state, status){
      state.noResultSearchPage = status;
  },
    [SHOW_NOT_RESULT_SEARCH](state, status){
        state.noResultSearch = status;
    },
    [SEARCH_SET_COUNT](state,){ 
        state.limit += 15;
        console.log('changeLimit', state.limit)
    },
    [RESET_LIMIT](state, count){ 
      state.limit = 15;
      console.log('resetLimit', state.limit)
  },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
